import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SkeltonEvents = () => {

    return (

        <div className="d-flex w-100 flex-column" >

            <Skeleton variant="rectangular" animation="wave" sx={{ bgcolor: 'grey.300', borderRadius: 2 }} width={'100%'} height={60} />
            
            <div className="d-flex flex-row justify-content-center flex-wrap mt-3" >

                {Array.from({ length: 3 }).map((k,i) => (
                    <Skeleton key={i} variant="rectangular" sx={{ bgcolor: 'grey.300', borderRadius: 2, marginX: '20px', marginY: '10px' }} width={280} height={500} />
                ))}

            </div>

        </div>
    );

}

export default SkeltonEvents;
