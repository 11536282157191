import axios from 'axios';
import produce from "immer"

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/testimonies`;

const GET_TESTIMONIES = 'GET_TESTIMONIES';


export const getTestimonies = () => async (dispatch) => {
    dispatch({type: 'LOAD_TESTIMONIES', payload: true });
    const response = await axios.get(url);
    const faqsData = response.data;

    dispatch({
        type: GET_TESTIMONIES,
        payload: faqsData,
    });
    dispatch({type: 'LOAD_TESTIMONIES', payload: false });
};


const TestimoniesState = {

    allTestimonies: [],    
}

const testimoniesReducer = (state = TestimoniesState, action) => {

    let nextState

    switch (action.type) {

        case GET_TESTIMONIES:

            nextState = produce(state, draftState => {
                draftState.allTestimonies = action.payload
            })
    
            return nextState || state;

        default:
        return state;
    }

};

export default testimoniesReducer;