import React from 'react';
import './howItWork.css'
import YouTube from 'react-youtube';
import { useTranslation } from 'react-i18next'

const HowItWork = () => {


    const {t} = useTranslation();
    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.stopVideo();
    }

    return (

        <div  style={{marginTop: 50, marginBottom: 50, scrollMarginTop: 110, scrollMarginBottom: 500 }} id="how_it_works" className="container" >

            <div className='event_group_title d-flex'>
                <h3 style={{fontSize: 22, fontWeight: 'bold', marginBottom: 5}} >{t("howitwork:How")}</h3>
            </div>

            <hr id='divider_events' />
        
            <div className="d-flex justify-content-center align-items-center my-4 " >

                <div  className="col-lg-7 col-md-10 bg-black col-12 rounded overflow-hidden d-flex justify-content-center" >
                    
                    <YouTube 
                        videoId="Tc7Bxcu22BM" 
                        onReady={onPlayerReady}
                        opts={
                            {
                                height: '100%',
                                width: '100%',
                                playerVars: {
                                // https://developers.google.com/youtube/player_parameters
                                autoplay: 1,
                                loop: 1,
                                modestbranding: 1,
                                showinfo: 0,
                                playsinline: 1,
                                rel: 0
                                },
                            }
                        } 
                        className="w-100"
                        style={{height: 380}}
                    />

                </div>

            </div>

        </div>

    );
}

export default HowItWork;
