const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const LockersApi = {

    async getLockerStock(event_id) {

        const response = await fetch(baseUrl + `/reservations/eventsReservations/${event_id}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        )
        const data = await response.json()
        if(response.ok)
        return data

        return null

    }


}