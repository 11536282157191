import axios from 'axios';
import produce from "immer"
const SEND_OPT_VERIFY = 'SEND_OPT_VERIFY';

const generateCode = () => {
    let code = '';
    for (let i = 0; i < 6; i++) {
        code += Math.floor(Math.random() * 10);
    }
    return code;
}


export const sendOptVerify = (telephone) => async dispatch => {
    const code = generateCode();
    const data = {
        telephone,
        code,
    };
    const url = `${process.env.REACT_APP_PUBLIC_URL}/clients/verify`;
    const res = await axios.post(url, data);
    dispatch({
        type: SEND_OPT_VERIFY,
        payload: code,
    });
}


const optappState = {

    opt: {
        code: null,
        load: {
            loading: false,
            error: false
        }
    }
    
}


const optReducer = (state = optappState, action) => {

    let nextState

    switch (action.type) {

        case SEND_OPT_VERIFY:

            nextState = produce(state, draftState => {
                draftState.opt.code = action.payload
            })
    
            return nextState || state;

        default:
            return state;
    }
}

export default optReducer;