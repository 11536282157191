import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch, batch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import PhoneInput from 'react-phone-input-2';
import './recapitulatif.css';
import { setClientDetails } from '../../redux/reducers/reservations';
import { useTranslation } from 'react-i18next';
import './identification.css';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import parsePhoneNumber, {isValidPhoneNumber} from 'libphonenumber-js'




const Formulaire = () => {

  const { t } = useTranslation();

  const schema = yup.object().shape({

    client_name: yup.string().trim().required("le nom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un nom valide"),
    client_last_name: yup.string().trim().required("le prenom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un nom valide"),
    client_email: yup.string().required("le mail est requis").email('Entrez un email valide'),
    client_phone: yup.string().required("le numéro est requis").test("tel", "Télephone Invalide" , (value) => {
      if(value && value?.length > 3)
      {
        let phonenumber = parsePhoneNumber('+'+value).number
        if (isValidPhoneNumber(phonenumber)) {  
          return true;
        }
        return false;
      }
      else {
        return false;
      }
    }),
    friend1_name: yup.string().trim()
      .when('friend1_phone', (friend1_phone, schema) => {
        if (friend1_phone)
          return schema.required("le nom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un nom valide")
      }),
    friend1_phone: yup.string()
      .when('friend1_name', (friend1_name, schema) => {
        if (friend1_name)
          return schema.required("le numéro est requis").test("tel", "Télephone Invalide" , (value) => {
            if(value && value?.length > 3)
            {
              let phonenumber = parsePhoneNumber('+'+value).number
              if (isValidPhoneNumber(phonenumber)) {  
                return true;
              }
              return false;
            }
            else {
              return false;
            }
          })
      }),
    friend2_name: yup.string().trim()
      .when('friend2_phone', (friend2_phone, schema) => {
        if (friend2_phone)
          return schema.required("le nom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un nom valide")
      }),
    friend2_phone: yup.string()
      .when('friend2_name', (friend2_name, schema) => {
        if (friend2_name)
          return schema.required("le numéro est requis").test("tel", "Télephone Invalide" , (value) => {
            if(value && value?.length > 3)
            {
              let phonenumber = parsePhoneNumber('+'+value).number
              if (isValidPhoneNumber(phonenumber)) {  
                return true;
              }
              return false;
            }
            else {
              return false;
            }
          })
      })
  },

    [
      ['friend2_name',
        'friend2_phone'],
      ['friend1_name',
        'friend1_phone']
    ]

  ).required();

  const reservation = useSelector((state) => state.reservationReducer.reservationInProgres)

  const { register, control, handleSubmit, formState: { errors } } = useForm({

    defaultValues: {
      client_name: reservation.client_name || "",
      client_last_name: reservation.client_last_name || "",
      client_email: reservation.client_email || "",
      client_phone: reservation.client_phone,
      friend1_name: reservation.friend1_name || "",
      friend1_phone: reservation.friend1_phone || "",
      friend2_name: reservation.friend2_name || "",
      friend2_phone: reservation.friend2_phone || "",
    },

    resolver: yupResolver(schema)

  });


  const dispatch = useDispatch();

  const handleNext = (data) => {

    batch(() => {
      dispatch(setClientDetails(data))
      dispatch({
        type: 'SET_CLIENT_DETAILS_PANIER', payload: {
          id: reservation.event_id,
          info: data
        }
      })
      dispatch({ type: "NEXT_STEP" });
    })

  }

  const handleBack = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  }

  return (


    <div className="d-flex tw-w-full md:tw-mt-0 tw-mt-8 flex-column align-items-center">

      <div
        className="w-100 fieldset position-relative tw-flex tw-justify-center tw-items-center tw-flex-col"
        style={{
          marginTop: 10,
          borderColor: '#9A9FA5'
        }}
      >

        {/* <h4>INFORMATIONS PERSONNELLES</h4>
        <hr /> */}

        <div className="d-flex ident-section tw-w-full">

          <Form className=" tw-flex tw-w-full tw-flex-col" >

            <div className=" tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-justify-center sm:tw-justify-evenly " >

              <div className=" tw-flex tw-flex-col tw-w-[95%] sm:tw-w-[45%] " >

                <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >

                  <Label for="Nom">{t("form:name")}</Label>
                  <input
                    id="client_name"
                    placeholder={t("form:name")}
                    {...register("client_name")}
                    type="text"
                    className="form-control tw-h-[54px]"
                  />
                  <small className="error tw-mt-1 text-danger">
                    {errors.client_name?.message}
                  </small>

                </FormGroup>

              </div>

              <div className=" tw-flex tw-w-[95%] sm:tw-w-[45%]" >

                <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >

                  <Label for="lastName">{t("form:prename")}</Label>
                  <input
                    id="client_last_name"
                    placeholder={t("form:prename")}
                    type="text"
                    {...register("client_last_name")}
                    className="form-control tw-h-[54px]"
                  />
                  <small className="error tw-mt-1 text-danger">
                    {errors.client_last_name?.message}
                  </small>
                </FormGroup>

              </div>

            </div>

            <div className=" tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-justify-center sm:tw-justify-evenly " >

              <Controller
                control={control}
                name="client_phone"
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (

                  <div className=" tw-flex tw-w-[95%] sm:tw-w-[45%] " >

                    <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                      <Label for="Nom">{t("form:tel")}</Label>
                      <PhoneInput
                        {...field}
                        country={'fr'}
                        countryCodeEditable={false}
                        // value={info.client_phone}
                        containerClass="form-control tw-h-[54px]"
                        inputClass="  tw-h-[54px] tw-w-full "
                      // className="form-control tw-h-[54px]"
                      // onChange={(e) => setInfo((prev) => ({ ...prev, client_phone: e }))}
                      />
                      <small className="error tw-mt-1 text-danger">
                        {errors.client_phone?.message} 
                      </small>
                    </FormGroup>

                  </div>

                )}

              />

              <div className="tw-flex tw-w-[95%] sm:tw-w-[45%]" >

                <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >

                  <Label for="lastName">{t("form:email")}</Label>
                  <input
                    id="client_email"
                    placeholder={t("form:emailplac")}
                    type="email"
                    {...register("client_email")}
                    className="form-control tw-h-[54px]"
                  />
                  <small className="error tw-mt-1 text-danger">
                    {errors.client_email?.message}
                  </small>
                </FormGroup>

              </div>

            </div>

          </Form>

        </div>

        <div className="position-absolute px-3 d-flex tw-mx-4 tw-self-start" style={{ top: -15, backgroundColor: '#FFF8F8', height: 30, justifyContent: 'center', alignItems: 'center' }} >
          <span className="tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px] tw-font-bold tw-font-['Poppins']" >{t("form:infoperso")}</span>
        </div>

      </div>


      <div
        className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
        style={{
          marginTop: 40,
          borderColor: '#9A9FA5',
        }}
      >

        {/* <h4>PARTAGER ET DONNER L’ACCÈS À VOTRE LOCKER</h4>
        <hr /> */}

        <div className="position-absolute px-3 d-flex tw-mx-4 tw-self-start" style={{ top: -15, backgroundColor: '#FFF8F8', height: 30, justifyContent: 'center', alignItems: 'center' }} >
          <span className="tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px] tw-font-bold tw-font-['Poppins']" >{t("form:share")}</span>
        </div>

        <Form className=" tw-flex tw-w-full tw-flex-col" >

          <p className='contactFriend-p'>Contact 1</p>

          <div className='contactFriend tw-pt-2 tw-px-2 tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-justify-center sm:tw-justify-evenly'>

            <div className=" tw-flex tw-w-[95%] sm:tw-w-[45%] " >

              <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                <Label for="exampleEmail">
                  {t("form:namecomplet")}
                </Label>
                <input
                  id="friend1_name"
                  {...register("friend1_name")}
                  placeholder={t("form:name")}
                  type="text"
                  className="form-control tw-h-[54px]"
                />
                <small className="error tw-mt-1 text-danger">
                  {errors.friend1_name?.message}
                </small>
              </FormGroup>

            </div>

            <Controller
              control={control}
              name="friend1_phone"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (

                <div className=" tw-flex tw-w-[95%] sm:tw-w-[45%]" >

                  <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                    <Label for="Nom">{t("form:tel")}</Label>
                    <PhoneInput
                      {...field}
                      country={'fr'}
                      countryCodeEditable={false}
                      // value={info.client_phone}
                      containerClass="form-control tw-h-[54px]"
                      inputClass="  tw-h-[54px] tw-w-full "
                    // className="form-control tw-h-[54px]"
                    // onChange={(e) => setInfo((prev) => ({ ...prev, client_phone: e }))}
                    />
                    <small className="error tw-mt-1 text-danger">
                      {errors.friend1_phone?.message}
                    </small>
                  </FormGroup>

                </div>

              )}
            />

          </div>

        </Form>

        <Form className=" tw-flex tw-w-full tw-flex-col" >

          <p className='contactFriend-p'>Contact 2</p>

          <div className='contactFriend d-flex tw-pt-2 tw-px-2 tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-justify-center sm:tw-justify-evenly'>

            <div className="tw-flex tw-w-[95%] sm:tw-w-[45%]" >

              <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                <Label for="exampleEmail">
                  {t("form:namecomplet")}
                </Label>
                <input
                  id="friend2_name"
                  {...register("friend2_name")}
                  placeholder={t("form:name")}
                  type="text"
                  className="form-control tw-h-[54px]"
                />
                <small className="error tw-mt-1 text-danger">
                  {errors.friend2_name?.message}
                </small>
              </FormGroup>
            </div>

            <Controller
              control={control}
              name="friend2_phone"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (

                <div className="tw-flex tw-w-[95%] sm:tw-w-[45%]" >

                  <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                    <Label for="Nom">{t("form:tel")}</Label>
                    <PhoneInput
                      {...field}
                      country={'fr'}
                      // value={info.client_phone}
                      countryCodeEditable={false}
                      containerClass="form-control tw-h-[54px]"
                      inputClass="tw-h-[54px] tw-w-full "
                    // className="form-control tw-h-[54px]"
                    // onChange={(e) => setInfo((prev) => ({ ...prev, client_phone: e }))}
                    />
                    <small className="error tw-mt-1 text-danger">
                      {errors.friend2_phone?.message}
                    </small>
                  </FormGroup>

                </div>

              )}
            />

          </div>

        </Form>

        {/* <Form className=" tw-flex tw-w-full tw-flex-col" >

          <p className='contactFriend-p'>Contact 2</p>

          <div className='contactFriend d-flex tw-pt-2 tw-flex-row tw-w-full tw-justify-evenly'>

            <div className=" tw-flex tw-w-[45%]" >

              <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                <Label for="exampleEmail">
                  {t("form:namecomplet")}
                </Label>
                <Input
                  id="friend2_name"
                  onChange={handleChange}
                  placeholder= {t("form:name")}
                  type="text"
                  className="form-control tw-h-[54px]"
                />
              </FormGroup>

            </div>

            <div className=" tw-flex tw-w-[45%]" >

              <FormGroup className="tw-flex tw-flex-col tw-w-[100%]" >
                
                <Label for="phone1">
                  {t("form:tel")}
                </Label>
                <PhoneInput
                  country={'fr'}
                  value={info.friend2_phone}
                  containerClass="form-control tw-h-[54px]"
                  inputClass=" tw-h-[54px] tw-w-full "
                  onChange={(e) => setInfo((prev) => ({ ...prev, friend2_phone: e }))}
                />
              </FormGroup>

            </div>

          </div>

        </Form> */}

        <br />

      </div>


      <div className='d-flex align-items-center action_buton'>
        <button
          onClick={handleBack}
          className='prev_btn'
        >{t("general:prev")}</button>
        <button
          onClick={handleSubmit(handleNext)}
          className='next_btn'
        >{t("general:next")}</button>
      </div>
    </div>
  )
}


export default Formulaire;