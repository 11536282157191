import axios from "axios";

const GET_LOCKERS_STOCK = 'GET_LOCKERS_STOCK';


export const getLockersStock = (event_id) => async (dispatch) => {


    const res = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/reservations/eventsReservations/${event_id}`)
    const data = await res.data;

    dispatch({
        type: GET_LOCKERS_STOCK,
        payload: data,
    });

};

const lockerReducer = (state = [], action) => {

    switch (action.type) {

        case 'GET_LOCKERS_STOCK':
            return action.payload;

        default:
        return state;

    }
}

export default lockerReducer;