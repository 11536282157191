import React from "react";
import KitCamping from "./KitCamping";
import Battery from "./Battery";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReservationApi } from "../../api/reservationsApi";
import CircularProgress from "@mui/material/CircularProgress";

export default function ServicePlus({ event }) {
  
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres
  );
  const loadLocker = useSelector((state) => state.params.loadLocker);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBack = () => {
    if (reservation?.services?.includes("locker")) {
      dispatch({ type: "PREVIOUS_STEP" });
    } else {
      dispatch({ type: "SET_STEP", payload: 1 });
    }
  };
  const handleNext = async () => {
    dispatch({ type: "NEXT_STEP" });
  };
  const isSelected = reservation?.dates[0].battery || 
                     reservation?.dates[0].kit_camping || 
                     (reservation?.dates[0]?.extra?.length > 0 && reservation?.dates[0].kit_camping)
   
  return (
    <div className="w-100">
      {reservation?.services?.includes("battery") && <Battery event={event} />}
      {reservation?.services?.includes("camping") && <KitCamping  event={event} />}
      <div className="d-flex align-items-center action_buton">
        <button onClick={() => handleBack()} className="prev_btn">
          {t("general:prev")}
        </button>

        <button
          className="next_btn"
          style={{ opacity: isSelected ? "1" : "0.2" }}
          disabled={isSelected ? false : true}
          onClick={handleNext}
        >
          {loadLocker.loading ? (
            <CircularProgress size={30} sx={{ color: "#fff" }} />
          ) : (
            t("general:next")
          )}
        </button>
      </div>
    </div>
  );
}
