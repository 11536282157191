const dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const EventImage = ({ event }) => {
  return (
    <div className="d-flex flex-row sm:tw-justify-center ">
      <div className="d-flex flex-column event-img-div">
        {event && (
          <>
            <img
              className="rounded-1 "
              src={
                event.logo &&
                `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` + event.logo
              }
              alt={event.event}
            ></img>

            <div className="event-description flex-column d-none d-md-flex">
              <h5
                className="tw-w-full tw-min-h-[25px] tw-text-[15px] "
                style={{ fontWeight: "800" }}
              >
                {event.event.toUpperCase()}
              </h5>

              <span
                style={{ fontWeight: "600" }}
                className=" tw-text-[14px] tw-mt-[2px] "
              >
                {dayjs(event.start_date).utc().format("DD MMM YYYY") + " au "}
                {dayjs(event.end_date).utc().format("DD MMM YYYY")}
              </span>

              <span
                style={{ fontWeight: "400" }}
                className=" d-flex flex-row align-items-center tw-text-[13px] tw-mt-[10px] "
              >
                {/* <FaMapMarkerAlt size={15.5} style={{marginRight: 3}} />{' '} */}
                {event.city.replace(/^\w/, (c) => c.toUpperCase())}
              </span>
              <span
                style={{ fontSize: 14, fontWeight: "400" }}
                className="mt-2 d-flex flex-row align-items-center"
              >
                {/* <FaMapMarked size={15.5} style={{marginRight: 5}} />{' '} */}
                {event.country}
                {/* <ReactCountryFlag className="emojiFlag" countryCode={event.countryCode} style={{ fontSize: '1em',lineHeight: '1em',}}/>{'  '}{ event.country } */}
              </span>
            </div>
          </>
        )}
      </div>

      <div className="event-description flex-column d-md-none d-flex tw-ml-2">
        <h5
          className="tw-w-full tw-min-h-[25px] tw-text-[15px] "
          style={{ fontWeight: "800" }}
        >
          {event.event.toUpperCase()}
        </h5>
        <span
          style={{ fontWeight: "600" }}
          className=" tw-text-[12px] tw-mt-[5px]"
        >
          {dayjs(event.start_date).utc().format("DD MMM YYYY") + " au "}
          {dayjs(event.end_date).utc().format("DD MMM YYYY")}
        </span>
        <span
          style={{ fontSize: 13, fontWeight: "400" }}
          className="mt-3 d-flex flex-row align-items-center"
        >
          {/* <FaMapMarkerAlt size={17} style={{marginRight: 3}} />{' '}{ event.city } */}
          {event.city.replace(/^\w/, (c) => c.toUpperCase())}
        </span>
        <span
          style={{ fontWeight: "400" }}
          className=" d-flex flex-row align-items-center tw-text-[13px] tw-mt-[10px] "
        >
          {/* <FaMapMarked size={17} style={{marginRight: 5}} />{' '}{ event.country } */}
          {/* <Flag code={event.countryCode} style={{height: '10px'}}/>{' '} */}
          {event.country}
        </span>
      </div>
    </div>
  );
};

export default EventImage;
