import {useEffect} from "react"
import TopSlider from "./TopSlider";
import Search from "./Search";
import EventGroup from "./EventGroup";
import Partenaire from "./Partenaire";
import SkeltonEvents from "../skelton/SkeltonEvents";
import HowItWork from "../../components/pages/howItWork";


import { useSelector} from 'react-redux';

const Home = ({events}) => {

  useEffect(() => {
    // scrollToComponent()
    let id =  window.location.hash.substring(1)
    let element = document.getElementById(id);

    setTimeout(() => {
      if (element) {
        element.scrollIntoViewIfNeeded(true);
      }
    }, 200);
  }, [])

  const load = useSelector((state) => state.params.loadEvents);

  return (
    <>
      <div className="container tw-mb-[50px]">
        <TopSlider />
        <HowItWork/>
        <Search />
        <>
          {load ?

            <SkeltonEvents/>

            :

            Object.keys(events).map((k,i) => (
              <EventGroup key={i} dateRange={k} events={events[k]} />
            ))

          }
        </>
        {/* <Contact/> */}
      </div>
      <Partenaire/>

    </>
  );
}

export default Home;
