import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from "redux-saga"
import {rootSaga} from './sagas/indexSaga'
import { persistReducer } from 'redux-persist'

import eventReducer from './reducers/events';
import reservationReducer from './reducers/reservations';
import stepperReducer from './reducers/stepper';
import optReducer from './reducers/optVerify';
import containerReducer from './reducers/containers';
import lockerReducer from './reducers/lockers';
import paramsappReducer from './reducers/params';
import panierReducer from './reducers/panier';
import timerReducer from './reducers/timer';
import slideReducer from './reducers/slider';
import jobsReducer from './reducers/jobs';
import faqsReducer from './reducers/faqs';
import testimoniesReducer from './reducers/testimonies';
import partnersReducer from './reducers/partners';
import termsReducer from './reducers/legalText';

const configureStore = () => {

  const rootPersistConfig1 = {
    key: 'root1',
    storage: storage
  }

  const rootPersistConfig2 = {
    key: 'root2',
    storage: storage,
    blackList: ["reservationNewLoad"]
  }

  const rootPersistConfig3 = {
    key: 'root3',
    storage: storage,
    whiteList: ['containerDetails']
  }

  const rootPersistConfig4 = {
    key: 'root4',
    storage: storage
  }

  const rootPersistConfig5 = {
    key: 'root5',
    storage: storage
  }

  const rootPersistConfig6 = {
    key: 'root6',
    storage: storage
  }

  // const rootPersistConfig7 = {
  //   key: 'root7',
  //   storage: storage,
  //   whiteList: []
  // }

  const rootPersistConfig8 = {
    key: 'root8',
    storage: storage
  }

  const rootPersistConfig9 = {
    key: 'root9',
    storage: storage
  }

  const sagaMiddleware = createSagaMiddleware();

  const middleWares = [sagaMiddleware, thunk]

  const store = createStore(

    combineReducers({

      eventReducer: persistReducer(rootPersistConfig1, eventReducer),
      reservationReducer: persistReducer(rootPersistConfig2, reservationReducer),
      containers: persistReducer(rootPersistConfig3, containerReducer),
      locker: persistReducer(rootPersistConfig4, lockerReducer),
      stepper: persistReducer(rootPersistConfig5, stepperReducer),
      ontimepin: optReducer,
      params: paramsappReducer,
      timerReducer: timerReducer,
      panierReducer: persistReducer(rootPersistConfig8, panierReducer),
      sliderReducer: persistReducer(rootPersistConfig9, slideReducer),
      jobsReducer: jobsReducer,
      faqsReducer: faqsReducer,
      testimoniesReducer: testimoniesReducer,
      partnersReducer: partnersReducer,
      termsReducer: termsReducer
    }),  
    applyMiddleware(...middleWares)

  );

  sagaMiddleware.run(rootSaga);
  
  return store;

};

export default configureStore;
