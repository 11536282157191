import React, {useEffect} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import HomeThreeSingleFaq from './faqcomponent/HomeThreeSingleFaq'
import CircularProgress from '@mui/material/CircularProgress';

import { getFaqs } from '../../redux/reducers/faqs';
import { useDispatch, useSelector } from 'react-redux';


const Faq = () => {
    const load = useSelector((state) => state.params.loadFaqs);
    const faqs = useSelector((state) => state.faqsReducer.allFaqs);
    const lng = useSelector((state) => state.params.langage);

    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFaqs());
    }, []);

    if(load)
    {
        return (

            <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center" >
                <CircularProgress sx={{color: '#E7075B'}} />
            </div>
        )
    }

    return (

        <div className="w-100 ">
           
            <div style={{height: 300}} className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center" >

                <span style={{width: '70%'}} className="job-details-title">
                    {t("faq:titrfaq").toUpperCase()}
                </span>

            </div>

            <div className="container" >

                <div style={{width: '100%'}} className="mt-3 " >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {/* {breadcrumbs} */}
                        <span className="bread" >
                            <NavLink
                                to="/"
                                style={{
                                    textDecoration: 'none'
                                }}
                                id="bread"
                            >
                                {t("rgpd:home")}
                            </NavLink>
                        </span>
                        <span>
                        {t("faq:titrfaq")}
                        </span>
                    </Breadcrumbs>
                </div>

                <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center " >
                    {
                        faqs.length > 0 && faqs.map((index, id) => (
                            (lng?.slice(0, 2) === 'fr') || (lng?.slice(0, 2) === 'FR') ? (
                                
                                <HomeThreeSingleFaq key={id} question={index.question} answer={index.answer} />
                            ) : (
                                <HomeThreeSingleFaq key={id} question={index.english_question} answer={index.english_answer} />

                            )
                        ))
                    }

                </div>

            </div>

        </div>

    );

}

export default Faq;
