import React, {useEffect} from 'react';
import './jobs.css'
import {
    IoLocationSharp
} from 'react-icons/io5';
import { FiClock } from "react-icons/fi"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import { getJobs } from '../../redux/reducers/jobs';
import { useDispatch, useSelector } from 'react-redux';

const Jobs = () => {
    const load = useSelector((state) => state.params.loadJobs);
    const jobs = useSelector((state) => state.jobsReducer.alljobs);
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getJobs());
    }, []);

    if(load)
    {
        return (

            <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center" >
                <CircularProgress sx={{color: '#E7075B'}} />
            </div>
        )
    }


    return (

        <div className="w-100 container-fluid tw-flex-col tw-flex tw-items-center " >

                <div style={{ height: 300 }} className=" w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center" >

                    <span style={{ width: '70%' }} className="job-details-title">
                    {t("jobs:vacant").toUpperCase()}
                    </span>

                </div>
            

            <div className="col-12 rounded overflow-hidden d-flex flex-column align-items-center mt-3 tw-mb-10 " >

                {jobs.length > 0 && jobs.map((index, id) => (

                    <div key={id} style={{minHeight: 100, width: '90%', marginTop: 25}} className="jobs-item d-flex flex-row rounded-1" >
                        
                        <div 
                            style={{flex: 1}}
                            className="d-flex flex-column py-3 px-3" 
                        >
                            <span className="title-job" >
                                {index.title}
                            </span>

                            <div style={{marginTop: 5}} className="d-flex flex-1 flex-row align-items-center " >

                                <div className="d-flex flex-row align-items-center">
                                    <IoLocationSharp style={{fontSize: 22, marginRight: 4}} />
                                    <span className="text-job">{index.place}</span>
                                </div>

                                <div className="d-flex flex-row align-items-center" style={{marginLeft: 25}} >
                                    <FiClock style={{fontSize: 20, marginRight: 4}}/>
                                    <span className="text-job">{index.time}</span>
                                </div>

                            </div>

                        </div>

                        <button 
                            onClick={() => {
                                navigate('./'+index.id, { state: { data: jobs.find((item) => item.id === index.id) } } )
                            }} 
                            type="button" 
                            className="job-button"
                        >
                            {t("jobs:voir")}
                        </button>

                    </div>

                ))}

                {
                    jobs.length === 0 && (
                        <div className="col-12 rounded overflow-hidden d-flex flex-column align-items-center mt-3 tw-mb-10 " >
                             <div 
                            style={{flex: 1}}
                            className="d-flex flex-column py-3 px-3" 
                                >
                                <span className="" >Aucune offre disponible !</span>
                                </div>
                        </div>

                    )
                }
            </div>

        </div>

    );
}

export default Jobs;
