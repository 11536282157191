import {useState, useCallback} from "react"
import {useDispatch, useSelector, batch} from 'react-redux'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationManager } from 'react-notifications';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Label } from "reactstrap";
import { useDropzone } from 'react-dropzone';
import { FiDownloadCloud } from 'react-icons/fi'

import { useTranslation } from 'react-i18next';


const PostulerDialog = () => {

    const { id } = useSelector((state) => state.params.dialog.value);

    const {t} = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const formData = new FormData();
    
    const [load, setload] = useState(false);
    const [cv, setcv] = useState(false);
    const [file, setFile] = useState(null);

    const schema = yup.object().shape({
        name: yup.string().trim().required('Le nom est obligatoire'),
        email: yup.string().email('Email invalide').required('Le email est obligatoire'),
        phonenumber: yup.string().trim().required('Ce numero est obligatoire'),
    })

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });


    const handleClose = () => {
        dispatch({type: 'SET_DIALOG', payload: { active: false, view: null } })
    };

    const onSubmit = async(data) => {
        
        formData.append('fullname', data.name);
        formData.append('email', data.email);
        formData.append('phone', data.phonenumber);
        formData.append('job_id', parseInt(id,10));
        formData.append('cv', file);

        setload(true)

        try {
            const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/candidats`;
            const res = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setload(false)
            dispatch({type: 'SET_DIALOG', payload: { active: false, view: null } })
            NotificationManager.success( t("jobs:successPostul") , '', 5000);  
            navigate('/jobs')
        } catch (error) {
            setload(false)
        }

    };

    const onDrop = useCallback((acceptedFiles) => {
       // add file in fonrData
        setFile(acceptedFiles[0])
        setcv(true)
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (

        <>
            <DialogTitle sx={{backgroundColor: '#E7075B', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <span className="tw-font-bold tw-text-[15px] tw-text-white" >{t("dialog:titleJob")}</span>
            </DialogTitle>   

            <DialogContent sx={{width: 450}} >

                <DialogContentText id="alert-dialog-description">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label className="tw-mt-3 tw-text-xs" >{t("dialog:fullname")}</Label>
                        <input disabled={load} {...register("name")} type="text" placeholder="" className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline" id="number"/>
                        <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">{errors.name?.message}</p>

                        <Label className="tw-mt-3 tw-text-xs" >{t("dialog:email")}</Label>
                        <input disabled={load} {...register("email")} type="text" placeholder="" className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline" id="mail"/>
                        <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">{errors.email?.message}</p>


                        <Label className="tw-mt-3 tw-text-xs" >{t("dialog:phone")}</Label>
                        <input disabled={load} {...register("phonenumber")} type="text" placeholder="" className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline" id="phonenumber"/>
                        <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">{errors.phonenumber?.message}</p>

                        <Label className="tw-mt-3 tw-text-xs" >{t("dialog:dropCv")}</Label>
                    
                            <div {...getRootProps({ className: 'dropzone tw-bg-[#E8F0FE] tw-flex justify-content-center tw-border tw-rounded-[50px] tw-h-[300px] tw-w-full'})}>
                                <input {...getInputProps()} />
                                <div className='d-flex align-items-center justify-content-center flex-column'>
                                {
                                     cv > 0 ?
                                    (
                                    <section> 
                                          <p>{file.path}</p>
                                    </section>) 
                                    :
                                    (
                                    <section className=" tw-flex tw-flex-col tw-items-center tw-justify-center " >
                                        <FiDownloadCloud size={64} />
                                        <span className=" tw-text-[12px] " >Drop Files here or click to upload</span>
                                        <p className='text-secondary tw-text-[12px]'>
                                            Drop files here or click{' '}
                                            <a href='/' onClick={e => e.preventDefault()}>
                                            browse
                                            </a>{' '}
                                            thorough your machine
                                        </p>
                                    </section>
                                    )
                                }
                                </div>
                            </div>

                        <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">{errors.number?.message}</p>
                    </form>
                </DialogContentText>
                
            </DialogContent>

            <DialogActions>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]" onClick={handleSubmit(onSubmit)}>
                    {load ?

                        <CircularProgress size={16} sx={{color: 'white'}} />

                        :

                        t("dialog:envoyer")
                    }
                </button>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700" onClick={() => handleClose()}>{t("dialog:annuler")}</button>
            </DialogActions> 

        </>

    );

}

export default PostulerDialog;
