const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const converteToNoTimeZoneDate = (d) => {
  let sd = new Date(d);
  let isoString = sd.toISOString(); // returns "2023-08-11T03:00:00.000Z"
  let noTimezoneString = isoString.slice(0, -1); // removes "Z" from the end
  let noTimezoneDate = new Date(noTimezoneString);

  return noTimezoneDate;
};

const getMonthAndyear = (d) => {
  const a = new Date(d);
  return [months[a.getMonth()], a.getFullYear()];
};

const orderEventByDate = (events) => {
  const eventsByDate = {};
  events.forEach((event) => {
    const date = getMonthAndyear(event.start_date);
    const month = date[0];
    const year = date[1];

    if (!eventsByDate[`${month} ${year}`]) {
      eventsByDate[`${month} ${year}`] = [event];
    } else {
      eventsByDate[`${month} ${year}`].push(event);
    }
  });

  return eventsByDate;
};

export const rangeOfDates = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let current = start;
  let dates = [];

  while (current <= end) {
    dates.push(new Date(current.getTime()));
    current.setDate(current.getDate() + 1);
    current.setHours(
      start.getHours(),
      start.getMinutes(),
      start.getSeconds(),
      start.getMilliseconds()
    );
  }

  return dates;
};

export const daysBetween = (startDate, endDate) => {
  const dateBetween = rangeOfDates(startDate, endDate);
  return dateBetween.length;
};

// format date
export const formatDate = (date) => {
  if (!date) return "loading...";
  let d = date;
  if (typeof d === "string") {
    d = new Date(date);
  }
  const year = d.getFullYear();
  const month = months[d.getMonth()];
  const day = d.getDate();
  return `${day} ${month} ${year}`;
};

// return array of date in reservation
export const getReservationDates = (reservation) => {
  return reservation.map((r) => r.date);
};

export const timerDelay = 5;

export default orderEventByDate;
