import produce from "immer"

const stepperappState = {
    step: 0
}

const stepperReducer = (state = stepperappState, action) => {

    let nextState

    switch(action.type) {
        
        case 'SET_STEP':

            nextState = produce(state, draftState => {
                draftState.step = action.payload
            })
    
            return nextState || state;

        case 'NEXT_STEP':

            nextState = produce(state, draftState => {
                draftState.step = state.step + 1;
            })

            return nextState || state;

        case 'PREVIOUS_STEP':
            nextState = produce(state, draftState => {
                draftState.step = state.step - 1;
            })

            return nextState || state;

        default: 
            return state;
    }
};

export default stepperReducer;