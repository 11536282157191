export default {
  general: {
    continue: "continuer",
    next: "Suivant",
    prev: "Précédent",
    return: "Retour",
    retry: "Réessayer",
    cancel: "Annuler",
    select: "Sélectionner",
    errorconnexion: "Veuillez vérifier votre connexion puis réessayer",
    eventclosed: "Cet événement n'est plus disponible à la réservation.",
  },

  dialog: {
    titleCancel: "RÉSERVATION ANNULÉE",
    textCancel: "Le temps de sauvegade de votre locker est terminer.",
    backToHome: "Accueil",
    backToResa: "Reprendre",
    titleDelete: "SUPPRESION",
    textDelete: "Êtes-vous sûr(e) de vouloir supprimer l’évènement ",
    textDelete2: " de votre panier ?",
    yes: "OUI",
    no: "NON",
    titleConfirmCancel: "CONFIRMATION DE L'ANNULATION",
    textConfirmCancel:
      "Êtes-vous sûr(e) de vouloir annuler votre réservation ?",
    titleJob: "POSTULER A CE POSTE",
    fullname: "Nom complet (*)",
    email: "Addresse mail (*)",
    phone: "Numéro de téléphone (*)",
    dropCv: "Téléverser votre CV (*)",
    annuler: "ANNULER",
    envoyer: "POSTULER",
  },

  navigation: {
    howitwork: "Comment ça marche",
    Shop: "Boutique",
    Pro: "Pro",
    Partners: "Partenaires",
    Jobs: "Jobs",
    contact: "Contacts",
    Mycarts: "Mon Panier",
    en: "Anglais",
    fr: "Français",
  },

  partenaire: {
    Title: 'ILS ONT DIT "OUI"',
    Subtitle: "Ils nous font grandir",
  },

  howitwork: {
    How: "Comment ça marche ?",
  },

  search: {
    title: "Cochez une rubrique pour une recherche rapide",
    FesCon: "Festivals - Concerts",
    ParMus: "Sports Mécaniques",
    FoiSal: "Foires - Salons",
    EveCul: "Salles de spectacles",
    PlaPar: "Plages - Parcs aquatiques",
    ParZoo: "Parcs d'attractions - Zoos",
    EveSstad: "Événements Sportifs - Stades",
    SiteCenvi: "Sites touristiques",
  },

  card: {
    reserver: "RESERVER UN SERVICE",
  },

  timer: {
    text: "Votre réservation est encore disponible pendant",
    text2: "minutes",
  },

  stepper: {
    service: "Services",
    date: "Date",
    sizeandpri: "Tailles & Tarifs",
    authen: "Authentification",
    form: "Formulaire",
    pay: "Paiement",
    "service+": "Extras +",
  },

  services: {
    title: "CHOISISSEZ VOS SERVICES",
    text: "Choisissez le ou les services dont vous avez besoin pour votre évènement :",
    btn_start: "Annuler",
    btn_clicked: "Sélectionner",
    pic1: "CASIER",
    pic2: "BATTERIE",
    pic3: "KIT CAMPING",
    pic4: "GREEN CAB",
  },

  pickdate: {
    errorressa: "Erreur veuillez vérifier votre connexion puis réessayer",
    endevent1: "Cet événement a pris fin le",
    endevent2: "et n'est plus disponible à la réservation",
    passtitle1: "FORFAITS PASS",
    passtitle2: "FORFAIT POUR 24 H",
    passdescrip:
      "Un accès illimité à votre locker durant toute votre présence sur place.",
    days: "JOURS",
    day: "JOUR",
    daytitle: "FORFAITS JOURNALIERS",
    daydescrip:
      "Un accès à votre locker pour une journée complète selon les horaires du lieu ou de l’évènement.",
    errormindate: "Veuillez choisir au moins une date",
  },

  tarifcard: {
    errorchoose: "Vous devez choisir une taille de lockers",
    textchoose: "CHOISISSEZ VOTRE TAILLE DE LOCKERS",
    placechoose: "CHOISISSEZ L’EMPLACEMENT DE VOTRE LOCKER",
    placechooserror: "Vous devez choisir un emplacement pour votre locker.",
    tpromo: "tarif réduit",
    tnormal: "tarif normal",
    tdm: "tarif dernière minute",
    modif: "Modifier",
    size: "Taille",
    choise: "CHOISIR",
    dispo: "Disponible(s)",
    indispo: "Épuisé",
    charge: "CHARGEUR",
    durer: "POUR LA DURÉE DE L'EVENEMENT",
    Principale: "Entrée Principale",
    Camping: "Camping",
    Village: "Village",
  },

  identification: {
    confirmerobot: "Confirmez-nous que vous n’êtes pas un robot !",
    confirmerobot2: "Merci de confirmer que vous n'est pas un robot. Merci",
    confirmernum: "Veuillez vérifier votre numero de téléphone",
    ajoutertel:
      "Ajouter votre numéro de téléphone (pour recevoir votre code de vérification)",
    continuertel: "Continuer avec ce numéro de téléphone",
    sendcode: "Envoyer le code",
    resendcode: "Renvoyer un code",
    changenum: "Changer le numéro",
    resentext1: "En cas de non réception attendez ",
    resentext2: " secondes ",
    resentext3:
      "et réessayez à nouveau. (Pensez à vérifier votre numéro et votre réseau)",
    tapecoderecu: "Tapez le code de vérification reçu par sms",
    plhtapecoderecu: "Code de validation",
    errortotalchiffre: "Le code doit contenir 6 chiffres",
    codetrue: "Code accepté",
    codefalse: "Code erroné",
  },

  form: {
    name: "Nom",
    prename: "Prénom",
    tel: "Numéro de téléphone",
    email: "Email",
    emailplac: "Votre adresse email",
    infoperso: "INFORMATIONS PERSONNELLES",
    share: "PARTAGER ET DONNER L’ACCÈS À VOTRE LOCKER",
    namecomplet: "Nom complet",
    errorincom: "Vos champs sont incomplets",
    errormail: "Email invalide",
    errornom: "Le nom doit contenir être renseigné",
    errorprenom: "Le prénom doit contenir être renseigné",

    objet: "Objet",
    message: "Message",
    messagePl: "Ecrire ton message...",
    send: "Envoyer",
    contactus: "Contactez-nous",
    horaire: "NOS HORAIRES",
    date1: "du Lundi au Jeudi de 9h à 18h",
    date2: "et le Vendredi de 9h à 17h",
    sendonload: "Chargement ...",
  },

  pay: {
    soustotal: "Sous Total",
    fraiservice: "Frais de service",
    questionpromo: "Avez-vous un code promo ?",
    reduction: "Réduction ",
    cdpromo: "Code Promo",
    verif: "Confirmer",
    total: "Total",
    process: "Procéder au paiement",
    messagesuccess: "Renseignez vos informations et validez votre réservation",
    messageprocess: "Your payment is processing.",
    messagerequires: "Your payment was not successful, please try again.",
    messagedefault: "Something went wrong.",
    paynow: "Payer maintenant",
    success: "Confirmer ma reservation",
    accept: "J'ai lu et J'accepte",
    condition1: "les Conditions Générales d'Utilisation",
    condition2: "les Conditions Générales de Vente",
    and: "et",
  },

  jobs: {
    voir: "Voir l'offre",
    postuler: "Postuler",
    vacant: "NOS RECRUTEMENTS",
    indeed: "Voir l'offre sur Indeed",
    likedn: "Voir l'offre sur Linkedin",
    successPostul: "Votre candidature a bien été enregistré",
  },

  pagesuccess: {
    ticket: "TICKET",
    reserv: "Réservation",
    du: "Du",
    au: "au",
    titresucc: "CONFIRMATION DE RESERVATION",
    thanks: "Merci",
    reservtext1: "Votre réservation",
    reservtext2:
      "est confirmée et votre locker réservé. Vous allez recevoir un ",
    reservtext3: "et un ",
    reservtext4:
      "avec toutes les informations concernant la réservation de votre locker.",
    reservtextinfo:
      "En cas de non réception, consultez vos spams ou contactez-nous",
    return: "ACCUEIL",
    etape: "Les prochaines étapes :",
    reduction: "Réduction: ",
    total: "Total: ",
    succedFlash: "Votre réservation a bien été prise en compte",
    successPay: "PAIEMENT CONFIRMÉ",
    avant: "AVANT",
    apres: "APRÈS",
    pendant: "PENDANT",
    event: "L'ÉVÈNEMENT",
    etap1:
      "JE REÇOIS UN SMS ET UN MAIL AVEC MON QR CODE ET CODE D’ACCÈS (À CONSERVER PRÉCIEUSEMENT)",
    etap2: "J’ACCÈDE À MON LOCKER GRÂCE À MON QR CODE OU À MON CODE D’ACCÈS",
    etap3: "JE PARTAGE MON EXPÉRIENCESUR LES RÉSEAUX SOCIAUX",
    itallicMention:
      "EN CAS DE NON RÉCEPTION, CONSULTEZ VOS SPAMS OU CONTACTEZ-NOUS",
    qrcodeNofund: "Qr code non retrouvé, Veuillez contacter l'assistance",
  },

  pagecancel: {
    titrecancel: "ANNULATION DE MA RESERVATION",
    cancel: "CONFIRMER",

    quiz: "Vous pouvez annuler la réservation jusqu'à 60 minutes avant l'ouverture de l'événement.",
    quiz11:
      "En confirmant l'annulation, le remboursement sera effectué dans les 72 heures selon les délais bancaires déduits des frais de service de 0,80 euros.",

    quiz2: "Malheureusement il n’est plus possible d’annuler",
    raison1:
      "Votre casier a dejá été ouvert . Vous ne pouvez plus annuler la reservation.",
    raison2: "Vous avez dejá annulé votre reservation.",
    raison3:
      "Lévénement a déjà commencer. Vous ne pouvez plus annuler la reservation.",

    subquiz:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium",
    subquiz2: "Cet évènement a débuté le ",
    subquiz3: "Votre reservation a dejá été annulée.",
    cancelFlash: "Votre réservation a bien été annulée",
  },

  rgpd: {
    titrgpd: "Règlement général sur la protection des données",
    home: "Accueil",
    next: "suiv",
    prev: "prec",
    of: "sur",
  },

  cgu: {
    titrcgu: "Conditions générales d'utilisation",
  },

  cgv: {
    titrcgv: "Conditions générales de vente",
  },

  faq: {
    titrfaq: "Foire aux questions",
  },

  mentionlegal: {
    titrmention: "Mentions légales",
  },

  aboutus: {
    titrabout: "On Parle de nous !",
  },

  help: {
    titrhelp: "Aide en ligne",
    onligne: "Chat en ligne",
    mail: "Envoyer un mail",
    whatsapp: "Envoyer un message whatsapp ",
    titrapp: "Télécharger notre application mobile",
  },

  footer: {
    about: "A propos",
    help: "Aide en ligne",
    faq: "Foire aux questions",
    cancel: "Politique d'annulation",
    talk: "On parle de nous",
    contact: "Contacts",
    team: "Equipe",
    mention: "Mentions légales",
  },
};
