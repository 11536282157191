import axios from 'axios';
import produce from "immer"

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/jobs`;

const GET_JOBS = 'GET_JOBS';


export const getJobs = () => async (dispatch) => {
    dispatch({type: 'LOAD_JOBS', payload: true });
    const response = await axios.get(url);
    const jobsData = response.data;

    dispatch({
        type: GET_JOBS,
        payload: jobsData,
    });
    dispatch({type: 'LOAD_JOBS', payload: false });
};


const jobsState = {

    alljobs: [],    
}

const jobsReducer = (state = jobsState, action) => {

    let nextState

    switch (action.type) {

        case GET_JOBS:

            nextState = produce(state, draftState => {
                draftState.alljobs = action.payload
            })
    
            return nextState || state;

        default:
        return state;
    }

};

export default jobsReducer;