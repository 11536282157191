import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import ReactCountryFlag from "react-country-flag";

//import Flag from '../../flag/Flag';


import './eventCard.css';
import { 
    FaMapMarkerAlt, 
    FaMapMarked, 
    FaCalendarAlt 
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next'
import { AspectRatio } from 'react-aspect-ratio' 
import { getAnalytics, logEvent } from "firebase/analytics";

const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const EventCard = ({ evenement }) => {

    const {t} = useTranslation();
    const { id, event, start_date, end_date, country, city, logo, countryCode } = evenement;
    const navigate = useNavigate()
    const analytics = getAnalytics();

    return(

        <div className="event_card">

            <div
                className=" tw-cursor-pointer"
                onClick={() => {
                    logEvent(analytics, 'select_item', {
                        item_list_name: evenement.type,
                        items: [
                          {
                            item_id: evenement.id,
                            item_name: evenement.event,
                            affiliation: evenement.organizer_name
                          }
                        ]
                    });
                    navigate(`./reservation/${id}`, { state: evenement })
                }} 
            >

                <AspectRatio ratio="269/445" style={{ maxWidth: '269px', borderRadius: 5, backgroundColor: 'rgb(240,210,210)' }}>
                    <img src={logo && `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` + logo} className="tw-rounded-[4px]" alt={event} />
                </AspectRatio>

            </div>

            <div className="event_description">

                <div className=" tw-w-full tw-h-[50px] tw-flex tw-items-center " >
                    <h2>{event.toUpperCase()}</h2>
                </div>
            
                
                <span className="tw-flex tw-flex-row tw-mt-2 " >
                    <FaCalendarAlt size={18} />
                    <span className=" tw-line-clamp-1 " style={{paddingLeft: '8px'}}>
                        {dayjs(start_date).utc().format('DD MMM YYYY') + ' - '} 
                        {dayjs(end_date).utc().format('DD MMM YYYY')}
                    </span>
                </span>

                <span className="tw-flex tw-flex-row tw-mt-2 tw-items-center" >
                    <FaMapMarkerAlt />
                    <span className=" tw-line-clamp-1" style={{paddingLeft: '8px'}}>{city.replace(/^\w/, c => c.toUpperCase())}</span>
                </span>

                <span className="tw-flex tw-flex-row tw-mt-1.5 tw-items-center">
                    {/* <ReactCountryFlag className="" countryCode={countryCode || 'fr'} style={{ fontSize: '1.5em',lineHeight: '1.5em'}}/> */}
                    {/* <FaMapMarked/> */}
                    {/* <MDBIcon flag='us' /> */}
                   {/** <Flag code={countryCode} style={{height: '10px'}}/> */}
                    <span className="" style={{paddingLeft: '8px'}}>{country}</span>
                </span>

            </div>

            <button 
                type="button"
                onClick={() => {
                    logEvent(analytics, 'select_item', {
                        item_list_name: evenement.type,
                        items: [
                          {
                            item_id: evenement.id,
                            item_name: evenement.event,
                            affiliation: evenement.organizer_name
                          }
                        ]
                    });
                    // console.log(evenement);
                    navigate(`./reservation/${id}`, { state: evenement })
                }} 
            >
                {t("card:reserver")}
            </button>

        </div>

    )
};

export default EventCard;
