import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './identification.css';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  Label
} from "reactstrap";

const IdentificationPass = () => {

  const {t} = useTranslation();
  const reservation = useSelector((state) => state.reservationReducer.reservationInProgres)
  const [recapval, setRecapval] = useState(null)
  const recaptchaRef = useRef(null)

  const dispatch = useDispatch();

  const onRecapTch = (v) => {
    setRecapval(v)
  }

  const onExpired = () => {
    setRecapval(null)
  }

  const changeNumber = () => {

    batch(() => {
        dispatch({ type: "DEL_IDENTIFICATION" });
        dispatch({ type: "DEL_IDENTIFICATION_TO_PANIER", payload: reservation.event_id});
    })

  }

  const handleNext = () => {

    if (!recapval) {
      return NotificationManager.error( t("identification:confirmerobot2"), 'Erreur', 5000);
    }

    batch(() => {
      //dispatch(setClientDetails({...info, "authentification": true}))
        //   dispatch({type: 'SET_CLIENT_DETAILS_PANIER', payload: {
        //     id: reservation.event_id,
        //     info: {...info, "authentification": true}
        //   }})
      dispatch({ type: "NEXT_STEP" });
    })

  }

  const handleBack = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  }

  return (

    <div className="ident-div tw-w-full md:tw-mt-0 tw-mt-8 d-flex flex-column align-items-center">

      <div className="tw-w-full tw-flex tw-flex-col " >

        <div className={"position-relative tw-ring-1  tw-px-3 tw-pt-[30px] tw-pb-4 tw-rounded-md tw-w-full tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-justify-center tw-items-center "+(
          recapval ? "tw-ring-green-200" : "tw-ring-slate-300 "
        )} >
        
          <div className=" tw-text-[14px] sm:tw-mr-2 tw-mr-0  " style={{fontWeight: 'bold'}} >
            {t("identification:confirmerobot")}
          </div>

          <div className='tw-mx-2 md:tw-mt-0 tw-mt-4 '>
            <ReCAPTCHA
              sitekey="6LcFKQMiAAAAAKwTVFIYwK1MwhlYHAlthqXp8urb"
              onChange={onRecapTch}
              ref={recaptchaRef}
              onExpired={onExpired}
              size="compact"
              // onErrored={onRecapError}
            />
          </div>

          <div className={"tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-w-[50px] tw-h-[50px] tw-absolute -tw-top-[18px] -tw-left-[18px] tw-rounded-full "+(
            recapval ? "tw-bg-green-500" : "tw-bg-slate-300 "
          )} >
            1
          </div>

        </div>

    
        <div className={"position-relative tw-ring-1 tw-mt-10 tw-px-3 tw-pt-[40px] tw-pb-4 tw-rounded-md tw-w-full tw-flex tw-flex-col "+(
            "tw-ring-green-200"
        )} 
        >
        
          <span id='exLabel' className=" tw-mb-4" >{t("identification:continuertel")}</span>

          <div className=" tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 " >

            <div className="tw-text-[14px] tw-flex tw-flex-1" style={{fontWeight: 'bold'}} >
                
              <FormGroup className="tw-flex tw-w-full" >

                {/* <Label for="Nom">{t("identification:continuertel")}</Label> */}
                <PhoneInput
                  disabled={true}
                  country={'fr'}
                  value={reservation.client_phone}
                  containerClass="form-control tw-h-[40px] tw-min-w-[160px] tw-max-w-[350px] tw-self-start "
                  inputClass="tw-h-[20px] tw-w-full tw-bottom-1 "
                />

              </FormGroup>

            </div>

            <div className=''>

              <button className="tw-h-[40px] tw-flex tw-justify-center tw-items-center" 
                onClick={() => changeNumber()}
              >     
                {t("identification:changenum")}     
              </button>

            </div>

          </div>

          <div className={"tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-w-[50px] tw-h-[50px] tw-absolute -tw-top-[18px] -tw-left-[18px] tw-rounded-full "+(
            "tw-bg-green-500" 
          )} >
            2
          </div>

        </div>

      </div>


      <div className='d-flex align-items-center action_buton'>

        <button
          onClick={() => handleBack()}
          className='prev_btn'
        >{t("general:prev")}</button>

        <button
          onClick={() => handleNext()}
          className='next_btn'
        >{t("general:next")}</button>

      </div>

    </div>
  )
}

export default IdentificationPass;
