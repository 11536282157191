import React, {useEffect, Fragment} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './mentionLegal.css'

const DownloadAppPage = () => {

    const links = useSelector((state) => state.sliderReducer.allLinks);
    const lng = useSelector((state) => state.params.langage);

    const {t} = useTranslation();

    const openChat = () => {
        // simulate a click on this class : cc-dc5e
        const element = document.getElementsByClassName('cc-15e7')[0];

        element.click();

      };

    return (

        <div className="w-100 ">
           
            <div style={{height: 300}} className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center" >

                <span style={{width: '70%'}} className="job-details-title">
                    {t("help:titrapp").toUpperCase()}
                </span>

            </div>

            <div className="container" >

                <div style={{width: '100%'}} className="mt-3 " >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {/* {breadcrumbs} */}
                        <span className="bread">
                            <NavLink
                                to="/"
                                style={{
                                    textDecoration: 'none'
                                }}
                                id="bread"
                            >
                                {t("rgpd:home")}
                            </NavLink>
                        </span>
                        <span>
                            {t("help:titrapp")}
                        </span>
                    </Breadcrumbs>
                </div>

                <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center " >
                    <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-600 position-relative tw-flex tw-items-center tw-flex-col md:tw-flex-col lg:tw-flex-row tw-justify-evenly job-details-header">
                        <a href={ links?.apple_link ? links.apple_link : "#"} rel="noreferrer" className="tw-w-[190px] tw-h-[90px] tw-mx-2 " > 
                            <div className="tw-w-full tw-h-full d-flex flex-column justify-content-center align-items-center">
                                <img src={lng === "fr" ? require('../../assets/img/app-storefr.png') : require('../../assets/img/app-store.png') } alt="playstore" />
                            </div>
                        </a>

                        <a href={ links?.google_link ? links.google_link : "#" } rel="noreferrer" className="tw-w-[210px] tw-h-[100px] tw-mx-2" > 
                            <div className="tw-w-full tw-h-full d-flex flex-column justify-content-center align-items-center">
                                <img src={lng === "fr" ? require('../../assets/img/google-play-badgefr.png') : require('../../assets/img/google-play-badge.png') } alt="playstore" />
                            </div>
                        </a>
                        

                    </div>

                </div>
            </div>

        </div>

    );

}

export default DownloadAppPage;
