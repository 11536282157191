export default {
  general: {
    continue: "Continue",
    next: "Next",
    prev: "Previous",
    return: "Return",
    retry: "Retry",
    cancel: "Cancel",
    select: "Select",
    errorconnexion: "Please check your connection then try again",
    eventclosed: "This event is no longer available for booking.",
  },

  dialog: {
    titleCancel: "CANCELLED RESERVATION",
    textCancel: "The saving time of your locker is over.",
    backToHome: "Home",
    backToResa: "Resume",
    titleDelete: "DELETE",
    textDelete: "Are you sure you want to delete the event  ",
    textDelete2: " of your card?",
    yes: "YES",
    no: "NO",
    titleConfirmCancel: "CONFIRMATION OF CANCELLATION",
    textConfirmCancel: "Are you sure you want to cancel your reservation ?",
    titleJob: "APPLY FOR THIS POSITION",
    fullname: "Full name (*)",
    email: "Mail address (*)",
    phone: "Phone number (*)",
    dropCv: "Upload your resume (*)",
    annuler: "CANCEL",
    envoyer: "APPLY",
  },

  navigation: {
    howitwork: "How it works",
    Shop: "Shop",
    Pro: "Pro",
    Partners: "Partners",
    Jobs: "Jobs",
    contact: "Contacts",
    Mycarts: "My Carts",
    en: "English",
    fr: "French",
  },

  partenaire: {
    Title: 'THEY SAID "YES"',
    Subtitle: "They make us grow",
  },

  howitwork: {
    How: "How it works ?",
  },

  search: {
    title: "Check a section for a quick search",
    FesCon: "Festivals - Concerts",
    ParMus: "Mechanical Sports",
    FoiSal: "Fairs - Shows",
    EveCul: "Theaters",
    PlaPar: "Beaches - Water parks",
    ParZoo: "Amusement parks - Zoos",
    EveSstad: "Sports Events - Stadiums",
    SiteCenvi: "Tourist sites",
  },

  card: {
    reserver: "BOOK A LOCKER",
  },

  timer: {
    text: "Your booking is still available for",
    text2: "minutes",
  },

  stepper: {
    service: "Services",
    date: "Date",
    sizeandpri: "Sizes & Prices",
    authen: "Authentication",
    form: "Form",
    pay: "Payment",
    "service+": "Services +",
  },
  services: {
    title: "CHOOSE YOUR SERVICES",
    text: "Choose the service(s) you need for your event:",
    btn_start: "Cancel",
    btn_clicked: "Select",
    pic1: "LOCKER",
    pic2: "POWER BANK",
    pic3: "KIT CAMPING",
    pic4: "GREEN CAB",
  },
  pickdate: {
    errorressa: "Error please check your connection then try again",
    endevent1: "This event ended on",
    endevent2: "and is no longer available for booking",
    passtitle1: "PACKAGE PASS",
    passtitle2: "PACKAGE FOR 24 H",
    passdescrip:
      "Unlimited access to your locker during your entire presence on site.",
    days: "DAYS",
    day: "DAY",
    daytitle: "DAILY PACKAGES",
    daydescrip:
      "Access to your locker for a full day according to the schedules of the place or event.",
    errormindate: "Please choose at least one date",
  },

  tarifcard: {
    errorchoose: "You must choose a lockers size",
    textchoose: "CHOOSE YOUR SIZE OF LOCKERS",
    placechoose: "CHOOSE THE PLACE OF YOUR LOCKER",
    placechooserror: "You must choose a location for your locker",
    tpromo: "reduced price",
    tnormal: "standard rate",
    tdm: "last minute rate",
    modif: "Edit",
    size: "Size",
    choise: "CHOOSE",
    dispo: "Available",
    indispo: "Sold out",
    charge: "CHARGER",
    durer: "FOR THE DURATION OF THE EVENT",
    Principale: "Main Entrance",
    Camping: "Camping",
    Village: "Village",
  },

  identification: {
    confirmerobot: "Confirm that you are not a robot!",
    confirmerobot2: "Please confirm that you are not a robot. Thanks",
    confirmernum: "Please verify your phone number",
    ajoutertel: "Add your phone number (to receive your verification code)",
    continuertel: "Continue with this phone number",
    sendcode: "send code",
    resendcode: "Resend a code",
    changenum: "Change number",
    resentext1: "If not received wait ",
    resentext2: " seconds ",
    resentext3:
      "and tried again. (Remember to check your number and your network)",
    tapecoderecu: "Enter the verification code received by SMS",
    plhtapecoderecu: "Validation code",
    errortotalchiffre: "The code must contain 6 digits",
    codetrue: "Code accepted",
    codefalse: "Code erroné",
  },

  form: {
    name: "Last name",
    prename: "First name",
    tel: "Phone number",
    email: "E-mail",
    emailplac: "Your email address",
    infoperso: "PERSONAL INFORMATIONS",
    share: "SHARE AND GIVE ACCESS TO YOUR LOCKER",
    namecomplet: "Full Name",
    errorincom: "Your fields are incomplete",
    errormail: "Invalid Email",
    errornom: "The name must contain be filled in",
    errorprenom: "The first name must contain be filled in",

    objet: "Object",
    message: "Message",
    messagePl: "Write your message...",
    send: "Send",
    contactus: "Contacts us",
    horaire: "OUR HOURS",
    date1: "Monday to Thursday from 9 a.m. to 6 p.m",
    date2: "and Friday from 9 a.m. to 5 p.m",
    sendonload: "Loading ...",
  },

  pay: {
    soustotal: "Subtotal",
    fraiservice: "Service charge",
    questionpromo: "Do you have a promo code?",
    reduction: "Reduction ",
    cdpromo: "Promo Code",
    verif: "Confirm",
    total: "Total",
    process: "Proceed to payment",
    messagesuccess: "Fill in your information and confirm your reservation",
    messageprocess: "Your payment is processing.",
    messagerequires: "Your payment was not successful, please try again.",
    messagedefault: "Something went wrong.",
    paynow: "Pay now",
    success: "Confirm my reservation",
    accept: "I have read and I accept",
    condition1: "the General Conditions of Use",
    condition2: "General Conditions of Sale",
    and: "and",
  },

  jobs: {
    voir: "See job offert",
    postuler: "To apply",
    vacant: "OUR RECRUITMENTS",
    indeed: "See the offer on Indeed",
    likedn: "See the offer on Linkedin",
    successPostul: "Your application has been registered",
  },

  pagesuccess: {
    ticket: "TICKET",
    reserv: "Reservation",
    du: "From",
    au: "to",
    titresucc: "CONFIRMATION OF RESERVATION",
    thanks: "Thanks",
    reservtext1: "Your reservation",
    reservtext2: "is confirmed and your locker reserved. You will receive a ",
    reservtext3: "and one ",
    reservtext4:
      "with all the information concerning the reservation of your locker.",
    reservtextinfo:
      "If you don't receive our newsletter, check your spam or contact us",
    return: "HOME",
    etape: "The next steps: ",
    reduction: "Discount: ",
    total: "Total: ",
    succedFlash: "Your reservation has been taken into account",
    successPay: "PAYMENT CONFIRM",
    avant: "BEFORE",
    apres: "AFTER",
    pendant: "DURING",
    event: "THE EVENT",
    etap1:
      "I RECEIVE AN SMS AND AN EMAIL WITH MY QR CODE AND ACCESS CODE (KEEP CAREFULLY)",
    etap2: "I ACCESS MY LOCKER USING MY QR CODE OR MY ACCESS CODE",
    etap3: "I SHARE MY EXPERIENCES ON SOCIAL NETWORKS",
    itallicMention: "IN CASE OF NON-RECEPTION, CHECK YOUR SPAM OR CONTACT US",
    qrcodeNofund: "Qr code not fund, Please contact our assistance",
  },

  pagecancel: {
    titrecancel: "CANCEL MY RESERVATION",
    cancel: "CONFIRM",

    quiz: "You can cancel the reservation up to 60 minutes prior to the opening of the event.",
    quiz11:
      "By confirming the cancellation, the refund will be made within 72 hours according to the banking terms deducted the service charge of 0.80 euros.",

    quiz2: "Unfortunately it is no longer possible to cancel your reservation",
    raison1:
      "Your locker has already been opened. You can no longer cancel the reservation.",
    raison2: "You have already cancelled your reservation.",
    raison3:
      "The event has already started. You can no longer cancel the reservation.",
    subquiz:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium",
    subquiz2: "This event started on ",
    subquiz3: "Your reservation has already been cancelled.",
    cancelFlash: "Your reservation has been cancelled",
  },

  rgpd: {
    titrgpd: "General Data Protection Regulation",
    home: "Home",
    next: "next",
    prev: "prev",
    of: "of",
  },

  cgu: {
    titrcgu: "Terms of Service",
  },

  cgv: {
    titrcgv: "Terms of Sales",
  },

  faq: {
    titrfaq: "Frequently Asked Questions",
  },

  aboutus: {
    titrabout: "They talk about us !",
  },

  mentionlegal: {
    titrmention: "Legal Notice",
  },

  help: {
    titrhelp: "Online help",
    onligne: "Online chat",
    mail: "Send email",
    whatsapp: "Send whatsapp message",
    titrapp: "Download our mobile application",
  },

  footer: {
    about: "About",
    help: "Online help",
    faq: "Frequently Asked Questions",
    cancel: "Cancellation Policy",
    talk: "They talk about us",
    contact: "Contact us",
    team: "Team",
    mention: "Legal Notice",
  },
};
