import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { updateReseration } from "../../../redux/reducers/reservations";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

export default function CheckoutForm({event}) {

  const {t} = useTranslation();
  const reservation = useSelector((state) => state.reservationReducer.reservationInProgres)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const analytics = getAnalytics();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t("pay:messagesuccess"));

          break;
        case "processing":
          setMessage(t("pay:messagesprocess"));
          break;
        case "requires_payment_method":
          setMessage(t("pay:messagerequires"));
          break;
        default:
          setMessage(t("pay:messagedefault"));
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    dispatch(updateReseration(reservation));
    dispatch({type: 'PUT_TIMER_STATUS', payload: 'pause'})
    console.log({reservation})
    console.log({event})
    const results = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${process.env.REACT_APP_ROOT_URL}/reservation/paiement`,
        receipt_email: reservation.client_email,
      },
      redirect: 'if_required'
    });


    if(results.error)
    {

      if (results.error.type === "card_error" || results.error.type === "validation_error") {
        setMessage(results.error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }

      setIsLoading(false);
      
      dispatch({type: 'PUT_TIMER_STATUS', payload: 'play'})

    }
    else
    {
      logEvent(analytics, 'purchase', {
        transaction_id: reservation.uid,
        value: reservation.total,
        tax: 0.80,
        currency: "EUR",
        coupon: reservation.code_promo,
        items: [
         {
          item_id: event.id,
          item_name: event.event,
          affiliation: event.organizer_name,
        }]
      });
      navigate(`../reservation/paiement`, { state: {prov: true} })
      setIsLoading(false);
    }

  };

  return (

    <>

      <form id="payment-form"  onSubmit={handleSubmit}>

        {

          <div className="tw-flex tw-flex-col tw-justify-center mb-3 " >
            <PaymentElement id="payment-element" />
            <button 
              disabled={isLoading || !stripe || !elements} 
              className=" tw-bg-[#E7075B] tw-self-center tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-h-[40px] hover:shadow-md focus:ring-2"
              id="submit"
            >
              {isLoading ? 

                <CircularProgress size={16} sx={{color: 'white'}} />
                :
                <span id="button-text">
                  {t("pay:paynow")} 
                </span>
              
              }
            </button>
            {/* Show any error or success messages */}
            {message && <small className=" tw-text-red-500 tw-mt-2 tw-flex tw-self-center " id="payment-message">{message}</small>}
          </div>

        }

      </form>

    </>

  );
}