import axios from 'axios';
import produce from "immer"

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/partners`;

const GET_PARTNERS = 'GET_PARTNERS';


export const getPartners = () => async (dispatch) => {
    dispatch({type: 'LOAD_PARTNERS', payload: true });
    const response = await axios.get(url);
    const partnersData = response.data;

    dispatch({
        type: GET_PARTNERS,
        payload: partnersData,
    });
    dispatch({type: 'LOAD_PARTNERS', payload: false });
};


const PartnersState = {

    allPartners: [],    
}

const partnersReducer = (state = PartnersState, action) => {

    let nextState

    switch (action.type) {

        case GET_PARTNERS:

            nextState = produce(state, draftState => {
                draftState.allPartners = action.payload
            })
    
            return nextState || state;

        default:
        return state;
    }

};

export default partnersReducer;