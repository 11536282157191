import { useEffect, useLayoutEffect, useCallback, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addEventIdToReservation } from "../../redux/reducers/reservations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import { getEventById } from '../../redux/api/events';
import Stepper from "./Stepper";
import PickDate from "./PickDate";
import TarifCard from "./TarifCard";
import EventImage from "./EventImage";
import Identification from "./Identification";
import IdentificationPass from "./IdentificationPass";
import Formulaire from "./Formulaire";
import Paiement from "./Paiement";

import { useTranslation } from "react-i18next";
import Timer from "./Timer";
import { timerDelay } from "../../utils";

import { EventsApi } from "../../api/eventsApi";
import Services from "./Services";
import ServicePlus from "./ServicePlus";

const dayjs = require("dayjs");

const Reservation = () => {
  const { t } = useTranslation();
  const currentStep = useSelector((state) => state.stepper.step);
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres
  );
  const panier = useSelector((state) => state.panierReducer.panier);
  const timer = useSelector((state) => state.timerReducer.timer);
  const backdrop = useSelector((state) => state.params.backDrop);

  let { id } = useParams();
  const dispatch = useDispatch();
  const [event, setevent] = useState(null);

  useLayoutEffect(() => {
    EventsApi.getEvent(id)
      .then((eventGet) => {
        setevent(eventGet);
        const indexPanier = panier.findIndex(
          (article) => article.event.id === eventGet.id
        );
        if (eventGet) {
          if (indexPanier !== -1) {
            // event exist in cardi
            const maxdate =
              dayjs(
                dayjs(panier[indexPanier].reservation.reservation_date)
                  .add(timerDelay, "m")
                  .utc()
                  .format()
              ).unix() * 1000;
            const refdate =
              dayjs(dayjs().add(10, "s").utc().format()).unix() * 1000;

            if (
              panier[indexPanier]?.reservation?.reservation_date &&
              refdate < maxdate
            ) {
              //delay of payement actif
              if (panier[indexPanier]?.event.id == reservation.event_id) {

                batch(() => {
                  dispatch({ type: "SET_TIMER", payload: maxdate });
                  dispatch({ type: "SET_STEP", payload: currentStep });
                });
              } else {
                batch(() => {
                  dispatch({ type: "SET_TIMER", payload: maxdate });
                  dispatch({ type: "SET_STEP", payload: currentStep });
                  dispatch({
                    type: "SET_CLIENT_DETAILS",
                    payload: panier[indexPanier].reservation,
                  });
                  dispatch({
                    type: "RESET_RESERVATION_PROGRES",
                    payload: panier[indexPanier].reservation,
                  });
                  // dispatch({type: 'GET_CONTAINER_STOCK', payload:{} })
                  dispatch({
                    type: "GET_LOCKERS_STOCK",
                    payload: panier[indexPanier].reservation.lockerStock,
                  });
                });
              }
            } else {
              // alert('hgjk')
              //revoke reservation uuid
              let data = {};

              data.event_id = panier[indexPanier].reservation.event_id;

              // data.date = undefined
              // data.dates = [] ;
              // reservation.dates.forEach(function(item){
              //     data.dates.push({
              //         container_id: item.container_id,
              //         date: item.date
              //     });
              // });
              // data.container_id = panier[indexPanier].reservation.container_id
              // if(panier[indexPanier].reservation?.pass)
              // {data.pass = panier[indexPanier].reservation.pass}

              batch(() => {
                dispatch({ type: "SET_TIMER", payload: false });
                dispatch({ type: "SET_STEP", payload: 0 });
                dispatch({ type: "SET_CLIENT_DETAILS", payload: {} });
                dispatch({ type: "RESET_RESERVATION_PROGRES", payload: data });
                dispatch({ type: "GET_CONTAINER_STOCK", payload: {} });
                dispatch({ type: "GET_LOCKERS_STOCK", payload: [] });
              });
            }
          } else {
            batch(() => {
              dispatch({ type: "SET_TIMER", payload: false });
              dispatch({ type: "SET_STEP", payload: 0 });
              dispatch({ type: "RESET_RESERVATION_PROGRES", payload: {} });
              dispatch({ type: "SET_CLIENT_DETAILS", payload: {} });
              dispatch({ type: "GET_CONTAINER_STOCK", payload: {} });
              dispatch({ type: "GET_LOCKERS_STOCK", payload: [] });
            });
            dispatch(addEventIdToReservation(eventGet.id));
          }
        } else {
          window.location.href = `/404`;
        }
      })
      .catch(() => {
        window.location.href = `/404`;
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <>
      {event && (
        <div className="mb-4 ">
          <div className=" tw-w-full ">
            {timer && (
              <div className="tw-flex tw-justify-end tw-flex-row tw-items-center tw-pb-1 ">
                <span className=" tw-mr-[3px] tw-text-[#FF0F0F] tw-text-[13px] tw-font-['Poppins'] ">
                  {t("timer:text")}
                </span>
                <div className=" tw-rounded-md tw-h-[35px] tw-w-[80px] tw-bg-[#FF0F0F] tw-flex tw-justify-center tw-items-center ">
                  <Timer date={timer} />
                </div>
                <span className=" tw-ml-[3px] tw-text-[#FF0F0F] tw-text-[13px] tw-font-['Poppins'] ">
                  {t("timer:text2")}
                </span>
              </div>
            )}

            <Stepper />
          </div>

          <div className="container-fluid tw-mb-12">
            <div className="d-flex flex-wrap mt-4 justify-content-center ">
              <div
                className={
                  "d-flex justify-content-lg-end  px-lg-4 justify-content-center " +
                  (currentStep === 2
                    ? "col-lg-3 col-md-3 col-sm-12"
                    : "col-lg-3 col-md-4 col-sm-12")
                }
              >
                <EventImage event={event} />
              </div>

              <div
                className={
                  "d-flex  justify-content-center pt-3 tw-px-0 sm:tw-px-1 md:tw-px-2 px-lg-3 " +
                  (currentStep === 2
                    ? "col-lg-9 col-md-9 col-12"
                    : "col-lg-8 col-md-8 col-12")
                }
              >
                {currentStep === 0 && <PickDate event={event} />}
                {currentStep === 1 && <Services event={event} />}
                {currentStep === 2 && <TarifCard event={event} />}
                {currentStep === 3 && <ServicePlus event={event} />}
                {currentStep === 4 &&
                  (!reservation?.authentification ? (
                    <Identification />
                  ) : (
                    <IdentificationPass />
                  ))}
                {currentStep === 5 && <Formulaire event={event} />}
                {currentStep === 6 && <Paiement event={event} />}
              </div>
            </div>
          </div>
        </div>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Reservation;
