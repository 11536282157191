import React from 'react';
import ReactDOM from 'react-dom/client';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
// import Navigation from './components/Navigation';
// import Footer from './components/Footer';

import { Provider } from 'react-redux'
// import store from './redux/ConfigStore';
import { persistStore} from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from './redux/ConfigStore';
import HttpsRedirect from 'react-https-redirect';

import "./translations/Localize"


const root = ReactDOM.createRoot(document.getElementById('root'));

const Store = configureStore();
const persistor = persistStore(Store)

root.render(


  <React.StrictMode>
    <HttpsRedirect>
      
      <Provider store={Store}>
        <PersistGate persistor={persistor}>
          <App />
          <NotificationContainer />
        </PersistGate>
      </Provider>

    </HttpsRedirect>
  </React.StrictMode>

);
