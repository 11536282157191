import produce from "immer"

const timerappState = {

    timer: false,
    timerStatus: null

}


const timerReducer = (state = timerappState, action) => {

    let nextState

    switch (action.type) {

        case 'SET_TIMER':

            nextState = produce(state, draftState => {
                draftState.timer= action.payload
            })

            return nextState || state;

        case 'PUT_TIMER_STATUS':

            nextState = produce(state, draftState => {
                draftState.timerStatus= action.payload
            })

            return nextState || state;


        default:
        return state;
    }

};

export default timerReducer;