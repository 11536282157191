import axios from "axios";
import produce from "immer";
import { batch } from "react-redux";

import { ReservationApi } from "../../api/reservationsApi";
const ADD_EVENT_ID = "ADD_EVENT_ID_TO_RESERVATION";
const SET_RESERVATION_DATE = "SET_RESERVATION_DATE";
const SET_RESERVATION_PASS = "SET_RESERVATION_PASS";
const SET_TARIF = "SET_TARIF";
const SET_TARIF_PASS = "SET_TARIF_PASS";
const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
const RESET_RESERVATION_INFOS = "RESET_RESERVATION_INFOS";
const CREATE_RESERVATION = "CREATE_RESERVATION";
const ADD_CODE_PROMO = "ADD_CODE_PROMO";
const SET_PLACE = "SET_PLACE";
const RESET_RESERVATION = "RESET_RESERVATION";
const SET_SERVICES = "SET_SERVICES";
const SET_BATTERY = "SET_BATTERY";
const SET_KIT_CAMPING = "SET_KIT_CAMPING";
const SET_EXTRA = "SET_EXTRA";

const token = `${process.env.REACT_APP_TOKEN}`;
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const addEventIdToReservation = (id) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_ID,
    payload: id || null,
  });
};

export const setReservationDate = (date) => async (dispatch) => {
  const choosenDate = { date: date };
  dispatch({
    type: SET_RESERVATION_DATE,
    payload: choosenDate,
  });
};

export const setReservationPass = (choosenDates) => async (dispatch) => {
  let dates = [];
  choosenDates.forEach((date) => {
    dates.push({ date: date });
  });
  dispatch({
    type: SET_RESERVATION_PASS,
    payload: { dates, pass: true },
  });
};

export const setReservationServices = (choosenServices) => async (dispatch) => {
  dispatch({
    type: SET_SERVICES,
    payload: choosenServices,
  });
};

export const setBattery = (elements) => async (dispatch) => {
  dispatch({
    type: SET_BATTERY,
    payload: elements,
  });
};

export const setKitCamping = (elements) => async (dispatch) => {

  dispatch({
    type: SET_KIT_CAMPING,
    payload: elements,
  });
};


export const setExtra = (elements) => async (dispatch) => {

  dispatch({
    type: SET_EXTRA,
    payload: elements,
  });
};

export const setTarif =
  (info, container_serial_nb, container_details, date) => async (dispatch) => {
    dispatch({
      type: SET_TARIF,
      payload: { info, container_serial_nb, container_details, date },
    });
  };

export const setTarifPass =
  (info, container_serial_nb, container_details, dates) => async (dispatch) => {
    dispatch({
      type: SET_TARIF_PASS,
      payload: { info, container_serial_nb, container_details, dates },
    });
  };

export const setReservationPlace =
  (place, container_serial_nb, container_details) => async (dispatch) => {
    dispatch({
      type: SET_PLACE,
      payload: { place, container_serial_nb, container_details },
    });
  };

export const setClientDetails = (info) => async (dispatch) => {
  dispatch({
    type: SET_CLIENT_DETAILS,
    payload: info,
  });
};

export const addPromoCode = (info) => async (dispatch) => {
  dispatch({
    type: ADD_CODE_PROMO,
    payload: info,
  });
};

export const resetRervationDetails = () => async (dispatch) => {
  dispatch({
    type: RESET_RESERVATION_INFOS,
  });
};

export const createReservation = (reservation) => async (dispatch) => {
  let today = new Date();
  today = today.toISOString().split("T")[0];
  const new_reservation = {
    event_id: reservation.event_id,
    reservation_date: today,
    container_serial_nb: reservation.container_serial_nb,
    container_details: reservation.container_details,
    // stringify each date
    dates: reservation.dates.map((date) => JSON.stringify(date)),
    pass: reservation.pass || false,
  };

  const res = await axios.post(
    `${process.env.REACT_APP_PUBLIC_URL}/reservations`,
    new_reservation,
    config
  );
  dispatch({
    type: CREATE_RESERVATION,
    payload: {
      uid: res.data.uid,
      container_serial_nb: res.data.container_serial_nb,
      container_details: res.data.container_details,
      reservation_date: new_reservation.reservation_date,
    },
  });
};

export const deleteReservation = (uid) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_PUBLIC_URL}/reservations/${uid}`,
      config
    );
    dispatch(resetRervationDetails());
    localStorage.removeItem("reservations");
    //delete containers from local storage
    localStorage.removeItem("containers");
  } catch (error) {
    console.log(error);
  }
};

export const updateReseration = (reservation) => async (dispatch) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_PUBLIC_URL}/reservations/${reservation.uid}`,
      reservation,
      config
    );
  } catch (error) {
    // if ((error.response.data.status = "closed")) {
    //   dispatch({
    //     type: "DEL_RESERVATION_TO_PANIER",
    //     payload: reservation.event_id,
    //   });
    //   dispatch({ type: "SET_STEP", payload: 0 });

    //   // return to home
    //   window.location.href = "/";
    // }
    return error;
  }
};

export const updateReserationPanier =
  (reservation, reservation2) => async (dispatch) => {
    batch(() => {
      // dispatch({type: "ADD_NEW_RESERVATION", payload: null })
      dispatch({ type: "LOAD_SUCCESS", payload: true });
    });

    let data = {};
    data.event_id = reservation.event_id;
    data.dates = [];

    try {
      batch(() => {
        dispatch({ type: "ADD_NEW_RESERVATION", payload: reservation2 });
        dispatch({ type: "SET_TIMER", payload: false });
        dispatch({ type: "RESET_RESERVATION_PROGRES", payload: data });
        dispatch({ type: "SET_STEP", payload: 0 });
        dispatch({
          type: "DEL_RESERVATION_TO_PANIER",
          payload: reservation.event_id,
        });
        dispatch({ type: "LOAD_SUCCESS", payload: false });
      });
    } catch (error) {
      console.log(error);
    }
  };

const reservationappState = {
  reservationInProgres: {},

  newReservation: null,

  reservationNewLoad: {
    loading: false,
    error: false,
  },
};

const reservationReducer = (state = reservationappState, action) => {
  let nextState;

  switch (action.type) {
    case ADD_EVENT_ID:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.event_id = action.payload;
      });

      return nextState || state;

    case "ADD_NEW_RESERVATION":
      nextState = produce(state, (draftState) => {
        draftState.newReservation = action.payload;
      });

      return nextState || state;

      case "UPDATE_RESERVATION":
        nextState = produce(state, (draftState) => {
          draftState.reservationInProgres = action.payload;
        });
        return nextState || state;

    case SET_RESERVATION_DATE:
      nextState = produce(state, (draftState) => {
        if (state.reservationInProgres.pass) {
          delete draftState.reservationInProgres.pass;
          draftState.reservationInProgres.dates = [action.payload];
        } else {
          const dates = state.reservationInProgres.dates || [];
          const index = dates.findIndex(
            (date) => date.date === action.payload.date
          );

          // if the date is already in the array, remove it
          if (index !== -1) {
            draftState.reservationInProgres.dates.splice(index, 1);
          } else {
            if (state.reservationInProgres.dates) {
              draftState.reservationInProgres.dates.push(action.payload);
            } else {
              draftState.reservationInProgres.dates = [];
              draftState.reservationInProgres.dates.push(action.payload);
            }
          }
        }
      });

      return nextState || state;

    case SET_RESERVATION_PASS:
      nextState = produce(state, (draftState) => {
        // if dates are already in the state, remove them
        if (state.reservationInProgres.dates) {
          delete draftState.reservationInProgres.dates;
        }
        if (state.reservationInProgres.pass) {
          delete draftState.reservationInProgres.pass;
        } else {
          draftState.reservationInProgres.pass = action.payload.pass;
          draftState.reservationInProgres.dates = action.payload.dates;
        }
      });

      return nextState || state;

    case SET_SERVICES:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.services = action.payload;
      });
      return nextState || state;

    case SET_BATTERY:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.dates =
        state.reservationInProgres.dates.map((date) => {
          return { ...date, battery: action.payload };
        });
      });
      return nextState || state;

      case SET_KIT_CAMPING:
        nextState = produce(state, (draftState) => {
          draftState.reservationInProgres.dates =
          state.reservationInProgres.dates.map((date) => {
            return { ...date, kit_camping: action.payload };
          });
        });
        return nextState || state;

        case SET_EXTRA:
          nextState = produce(state, (draftState) => {
            draftState.reservationInProgres.dates =
            state.reservationInProgres.dates.map((date) => {
              return { ...date, extra: action.payload };
            });
          });
          return nextState || state;

    case SET_TARIF:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.dates =
          state.reservationInProgres.dates.map((date) => {
            if (date.date === action.payload.date) {
              return { ...date, ...action.payload.info };
            }
            return date;
          });
        draftState.reservationInProgres.container_details =
          action.payload.container_details;
        draftState.reservationInProgres.container_serial_nb =
          action.payload.container_serial_nb;
      });

      return nextState || state;

    case SET_TARIF_PASS:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.dates =
          state.reservationInProgres.dates.map((date) => {
            return { ...date, ...action.payload.info };
          });
        draftState.reservationInProgres.container_details =
          action.payload.container_details;
        draftState.reservationInProgres.container_serial_nb =
          action.payload.container_serial_nb;
      });

      return nextState || state;

    case SET_CLIENT_DETAILS:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = {
          ...state.reservationInProgres,
          ...action.payload,
        };
      });

      return nextState || state;

    case RESET_RESERVATION_INFOS:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = {
          event_id: state.reservationInProgres.event_id,
        };
      });

      return nextState || state;

    case CREATE_RESERVATION:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = {
          ...state.reservationInProgres,
          ...action.payload,
        };
      });

      return nextState || state;

    case SET_PLACE:
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = {
          ...state.reservationInProgres,
          ...action.payload,
        };
      });

      return nextState || state;

    case "SET_RESERVATIONNEW_LOAD":
      nextState = produce(state, (draftState) => {
        draftState.reservationNewLoad = action.payload;
      });

      return nextState || state;

    case "DEL_IDENTIFICATION":
      nextState = produce(state, (draftState) => {
        delete draftState.reservationInProgres.authentification;
        delete draftState.reservationInProgres.client_phone;
      });

      return nextState || state;

    case "RESET_RESERVATION_PROGRES":
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = action.payload;
      });

      return nextState || state;

    case "ADD_CODE_PROMO":
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres = {
          ...state.reservationInProgres,
          ...action.payload,
        };
      });

      return nextState || state;

    case "RESET_RESERVATION":
      nextState = produce(state, (draftState) => {
        draftState.reservationInProgres.dates = action.payload.dates;
      });

      return nextState || state;

    default:
      return state;
  }
};

export default reservationReducer;
