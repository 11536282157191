import './footer.css';
import logo from '../assets/img/logo.png';
import { useSelector } from 'react-redux';
import {AiFillCaretRight} from "react-icons/ai";
import { useTranslation } from 'react-i18next'

const Footer = () => {

    const {t} = useTranslation();
    const notFound = useSelector((state) => state.params.notFound )
    const lng = useSelector((state) => state.params.langage)

    const links = useSelector((state) => state.sliderReducer.allLinks);

    return (

        <>

            {!notFound &&

                <div className="container-fluid bg-dark tw-py-10 " >

                    <div className="container footer-box">

                        <footer className="bg-dark text-white tw-py-4 text-center">

                            <div className="d-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-flex-wrap tw-w-full" >

                                <div className="tw-flex tw-w-[80px]" >

                                    <a href="#top" className="tw-w-[70px] tw-h-[70px]" > 

                                        <div className="logo2 tw-w-full tw-h-full ">
                                            <img src={logo} alt="logo" />
                                        </div>

                                    </a>

                                </div>    
                                
                                
                                <div className=" lg:tw-mt-[0px] tw-mt-[30px] lg:tw-flex-row tw-flex-col tw-flex tw-flex-1 lg:tw-justify-around lg:tw-ml-4" >

                                    <div className="tw-flex lg:tw-min-w-[150px] tw-flex-row tw-flex-wrap lg:tw-flex-col f-link mb-3 text-muted tw-text-left ">

                                        {/* <a href="#about" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:about")}
                                        </a> */}

                                        <a href="/faq" className=" tw-flex  tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:faq")}
                                        </a>

                                        <a href="/help" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:help")}
                                        </a>

                                        
                                        <a href="/On_parle_de_nous" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:talk")}
                                        </a>

                                        {/* <a href="#about" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:contact")}
                                        </a> */}
                                        
                                    </div>
                                    
                                    <div className="tw-flex lg:tw-min-w-[150px] tw-flex-row lg:tw-flex-col f-link mb-3 tw-flex-wrap text-muted tw-text-left ">

                                        <a href="/cgu" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            CGU
                                        </a>

                                        <a href="/rgpd" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            RGPD
                                        </a>

                                        <a href="/cgv" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            CGV
                                        </a>
                                        
                                    </div>

                                    <div className="tw-flex lg:tw-min-w-[150px] tw-flex-row lg:tw-flex-col f-link mb-3 tw-flex-wrap text-muted tw-text-left ">

                                        <a target="_blank" href={ links?.instagram ? links.instagram : "#" } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/instagram.png')} className="icon-filter" style={{width: 20, height: 20}} alt="instagram" />
                                            </span>
                                            Instagram
                                        </a>

                                        <a target="_blank" href={ links?.linkedin ? links?.linkedin : "#" } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/linkedin.png')} className="icon-filter" style={{width: 20, height: 20}} alt="linkedin" />
                                            </span>
                                            LinkedIn 
                                        </a>

                                        {/* <a target="_blank" href={ links?.tiktok ? links.tiktok : "#" } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/tik-tok.png')} className="icon-filter" style={{width: 20, height: 20}} alt="tik-tok" />
                                            </span>
                                            tik tok 
                                        </a> */}

                                        <a target="_blank" href={ links?.facebook ? links.facebook : "#"} rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/facebook.png')} className="icon-filter" style={{width: 20, height: 20}} alt="facebook" />
                                            </span>
                                            Facebook
                                        </a>
                                    
                                    </div>
                                    
                                    <div className="tw-flex lg:tw-min-w-[150px] tw-flex-row lg:tw-flex-col f-link mb-3 tw-flex-wrap text-muted tw-text-left ">

                                        {/* <a target="_blank" href={ links.linkedin } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/linkedin.png')} className="icon-filter" style={{width: 20, height: 20}} alt="facebook" />
                                            </span>
                                            Linkedin
                                        </a> */}

                                        <a target="_blank" href={ links?.whatsapp ? links.whatsapp : "#"  } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/whatsapp.png')} className="icon-filter" style={{width: 16, height: 16}} alt="whatsapp" />
                                            </span>
                                            WhatsApp
                                        </a>
                                        
                                        <a target="_blank" href={ links?.youtube ? links.youtube : '#' } rel="noreferrer" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span className=" tw-mr-2 " >
                                                <img src={require('../assets/img/youtube.png')} className="icon-filter" style={{width: 16, height: 16}} alt="whatsapp" />
                                            </span>
                                            YouTube
                                        </a>

                                        <a href="/Mention_legal" className=" tw-flex tw-flex-row tw-items-center hover:tw-text-white tw-text-slate-400 lg:tw-my-3 tw-my-1 tw-mx-2 " > 
                                            <span>
                                                <AiFillCaretRight/>
                                            </span>
                                            {t("footer:mention")}
                                        </a>

                                    </div>

                                    <div className="tw-flex lg:tw-min-w-[220px] tw-mt-3 lg:tw-mt-0 tw-items-center tw-flex-wrap tw-flex-row lg:tw-flex-col mb-2 text-muted tw-text-left ">

                                        <a href={ links?.apple_link ? links.apple_link : "#"} rel="noreferrer" className="tw-w-[170px] tw-h-[60px] tw-mx-2 " > 
                                            <div className="tw-w-full tw-h-full ">
                                                <img src={lng === "fr" ? require('../assets/img/app-storefr.png') : require('../assets/img/app-store.png') } alt="playstore" />
                                            </div>
                                        </a>

                                        <a href={ links?.google_link ? links.google_link : "#" } rel="noreferrer" className="tw-w-[170px] tw-h-[70px] tw-mx-2 " > 
                                            <div className="tw-w-full tw-h-full ">
                                                <img src={lng === "fr" ? require('../assets/img/google-play-badgefr.png') : require('../assets/img/google-play-badge.png') } alt="playstore" />
                                            </div>
                                        </a>

                                    </div>

                                </div>

                            </div>

                            <div>
                                <div className="col">
                                    <div className='copyright text-muted tw-mt-4 lg:tw-mt-8'>
                                        © {new Date().getFullYear()}{" "}, All Rights Reserved
                                    </div>
                                </div>
                            </div>

                        </footer>

                    </div>

                </div>

            }
        </>

    );
}

export default Footer;