import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Check } from "@mui/icons-material";
import produce from "immer";
import { useDispatch } from "react-redux";
import batery from "../../assets/icons/image 130.png";
import batts from "../../assets/icons/s+/batterie.png";

export default function Battery({ event }) {
  const { t } = useTranslation();
  const reservation =
    useSelector((state) => state.reservationReducer.reservationInProgres) || [];
  const [selection, setSelection] = useState(
    reservation?.dates[0]?.battery?.map((items) => {
      return items?.id;
    }) || []
  );

  const dispatch = useDispatch();

  const batt = [
    {
      id: "battery1",
      name: "BATTERIE STANDARD",
      price: 6,
      icon: batery,
      stock : 0,
      description : "*Selon votre utilisation et l'état de votre batterie."
    },
    {
      id: "battery2",
      name: "BATTERIE ILLIMITÉE",
      price: 10,
      icon: batts,
      stock : 0,
      description : "*Possibilité d'échanger votre batterie déchargée contre une batterie chargée de façon illimitée toute la journée."
    },
  ];
  if(event?.servicesStock?.battery)
  {
    for (let i = 0; i < batt.length; i++) {
      if( event.servicesStock.battery.find((item) => item.key === batt[i].id))
      {
        const exist = event.servicesStock.battery.find((item) => item.key === batt[i].id);

        batt[i].stock = exist.availableStock;
      }else
      {
        batt[i].stock = 1;
      }
    }
  }

  const handleSelection = (id) => {
    const copy = selection.includes(id)
      ? selection.filter((extraId) => extraId !== id)
      : [...selection, id];

    setSelection(copy);

    const payload = copy.map((BattId) => {
      const elements = batt.find((data) => data.id === BattId);
      
      return { id: elements.id, name: elements.name, price: elements.price };

    });

    if (!payload.length) {
      const updatedReservation = produce(reservation, (draft) => {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.battery;
          });
        }
      });

      dispatch({ type: "UPDATE_RESERVATION", payload: updatedReservation });
    } else {
      dispatch({
        type: "SET_BATTERY",
        payload,
      });
    }
  };

  return (
    <div className="w-100 tw-flex tw-flex-col tw-mb-8">
      <div
        className="w-100 tw-mt-16 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
        style={{
          borderColor: "#9A9FA5",
        }}
      >
        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row lg: tw-items-center tw-justify-center tw-gap-8">
          {batt?.map((data, i) => {
            const isSelected = selection?.includes(data?.id);
            if(data?.stock > 0) return (
              
              <div
                key={i}
                className="tw-w-full md:tw-w-[50%] lg:tw-w-[50%] xl:tw-w-[30%] tw-flex tw-flex-col tw-gap-2"
              >
                <div
                  style={{
                    border: isSelected ? "1px solid #E7075B" : "1px solid #ddd",
                    color: isSelected ? "green" : "#E7075B",
                  }}
                  className="tw-w-full tw-py-4 tw-text-center tw-border tw-flex tw-flex-col tw-items-center tw-rounded-xl"
                >
                  <div className="tw-w-full tw-text-[24px] md:tw-text-[20px] lg:tw-text-[18px] tw-text-[#000000] tw-font-['Poppins'] tw-font-medium">
                    {data?.name}
                  </div>

                  <div className="tw-h-[150px] tw-w-[150px]">
                    {" "}
                    <img
                      src={data.icon}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      alt="batt"
                    />
                  </div>
                  {i === 0 && (
                    <>
                    <div className="tw-flex tw-flex-col tw-items-start">
                      <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                        {" "}
                        <Check color="#E7075B" />
                        <h3
                          style={{ color: "#000" }}
                          className="tw-text-[14px]"
                        >
                          Puissance 5000mAh
                        </h3>
                      </div>
                      <div className="border tw-my-1 tw-opacity-[10]"></div>
                      <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                        {" "}
                        <Check color="#E7075B" />
                        <h3
                          style={{ color: "#000" }}
                          className="tw-text-[14px]"
                        >
                          Nombre de cycle : 2.5 charge*
                        </h3>
                      </div>
                      <div className="border tw-my-1 tw-opacity-[10]"></div>

                      <div className="border tw-my-1 tw-opacity-[10]"></div>
                      
                    </div>
                    
                    </>
                  )}
                  {i === 1 && data?.stock > 0 && (
                    <div className="tw-flex tw-flex-col tw-items-start">
                      <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                        {" "}
                        <Check color="#E7075B" />
                        <h3
                          style={{ color: "#000" }}
                          className="tw-text-[14px]"
                        >
                          Échange illimité
                        </h3>
                      </div>
                      <div className="border tw-my-1 tw-opacity-[10]"></div>
                      <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                        {" "}
                        <Check color="#E7075B" />
                        <h3
                          style={{ color: "#000" }}
                          className="tw-text-[14px]"
                        >
                          Puissance 5000mAh
                        </h3>
                      </div>
                      <div className="border tw-my-1 tw-opacity-[10]"></div>

                      <div className="border tw-my-1 tw-opacity-[10]"></div>
                    </div>
                  )}
                  <span className="title-forfait mt-2 tw-text-[12px] sm:tw-text-[14px] text-black md:tw-text-[28px]">
                    {data.price}€
                  </span>
                </div>
                <div className="tw-w-[95%] tw-my-8 tw-flex tw-flex-col tw-items-end tw-justify-end tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          
          <h1>
            
            <h1 style={{minHeight: "50px"}}>
              {data.description}
            </h1>
            
          </h1>
        </div>
                <button
                  onClick={() => handleSelection(data?.id)}
                  style={{
                    background: isSelected ? "#E7075B" : "#fff",
                    border: isSelected ? "1px solid #fff" : "1px solid #E7075B",
                    color: isSelected ? "#fff" : "#E7075B",
                  }}
                  className="tw-w-full tw-p-3 tw-rounded-xl"
                >
                  {isSelected
                    ? t("services:btn_start")
                    : t("services:btn_clicked")}
                </button>
              </div>
            );
          })}
        </div>
        
        <div
          className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
          style={{
            top: -15,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
            CHOISISSEZ VOTRE BATTERIE
          </span>
        </div>
      </div>
    </div>
  );
}
