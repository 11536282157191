import produce from "immer"

const panierappState = {

    panier: []

}


const panierReducer = (state = panierappState, action) => {

    let nextState

    switch (action.type) {

        case 'ADD_RESERVATION_TO_PANIER':

            const index = state.panier.findIndex((article) => article.event.id === action.payload.event.id )


            if(index === -1)
            {
                nextState = produce(state, draftState => {
                    draftState.panier.push(action.payload)
                })
            }
            else
            {
                nextState = produce(state, draftState => {
                    draftState.panier[index] = action.payload
                })
            }

            return nextState || state;

        case 'DEL_RESERVATION_TO_PANIER':

            const indexdel = state.panier.findIndex((article) => article.event.id === action.payload )

            if(indexdel !== -1)
            {
                nextState = produce(state, draftState => {
                    draftState.panier.splice(indexdel, 1)
                })
            }

            return nextState || state;

        
        case 'DEL_IDENTIFICATION_TO_PANIER':

            const indexdeliden = state.panier.findIndex((article) => article.event.id === action.payload )

            if(indexdeliden !== -1)
            {
                nextState = produce(state, draftState => {
                    delete draftState.panier[indexdeliden].reservation.authentification
                    delete draftState.panier[indexdeliden].reservation.client_phone
                })
            }

            return nextState || state;
    
                


        case 'SET_CLIENT_DETAILS_PANIER':

            const index2 = state.panier.findIndex((article) => article.event.id === action.payload.id )

            if(index2 === -1)
            {}
            else
            {
                nextState = produce(state, draftState => {
                    draftState.panier[index2].reservation = { ...state.panier[index2].reservation, ...action.payload.info } 
                })
            }

            return nextState || state;

        default:
        return state;
    }

};

export default panierReducer;