import axios from 'axios';
import produce from "immer"

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/faqs`;

const GET_FAQS = 'GET_FAQS';


export const getFaqs = () => async (dispatch) => {
    dispatch({type: 'LOAD_FAQS', payload: true });
    const response = await axios.get(url);
    const faqsData = response.data;

    dispatch({
        type: GET_FAQS,
        payload: faqsData,
    });
    dispatch({type: 'LOAD_FAQS', payload: false });
};


const faqsState = {

    allFaqs: [],    
}

const faqsReducer = (state = faqsState, action) => {

    let nextState

    switch (action.type) {

        case GET_FAQS:

            nextState = produce(state, draftState => {
                draftState.allFaqs = action.payload
            })
    
            return nextState || state;

        default:
        return state;
    }

};

export default faqsReducer;