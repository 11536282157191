const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const ContenairsApi = {

    async getContenairDetails(containerId) {

        const response = await fetch(baseUrl + `/containers/${containerId}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        )
        const data = await response.json()
        if(response.ok)
        return data

        return null

    }


}